@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
/*red*/
/*yellow*/
/*lightblue*/
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333333;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
  background: url(../images/common/body_pt.gif);
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  position: relative;
  padding-bottom: 90px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold !important;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

header#global_header .telwrap {
  margin-right: 15px;
}

header#global_header .tel2 {
  font-size: 28px;
  font-weight: 500;
  font-family: "Times New Roman";
}

header#global_header .tel2 span {
  font-size: 20px;
}

@media only screen and (max-width: 834px) {
  header#global_header .tel2 {
    margin-right: 10px;
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 640px) {
  header#global_header .tel2 {
    font-size: 1.4em;
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  header#global_header .tel2 {
    font-size: 1.4em;
  }
}

header#global_header .tel2 a {
  color: #333333;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_left {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: auto;
    margin-right: 30px;
  }
}

.hed_right {
  display: flex;
  justify-content: flex-end;
}

.hed_right a {
  color: #ffffff;
  font-size: 1.8em;
}

.hed_right .sns_fb {
  margin-right: 5px;
}

.hed_right .sns_insta {
  margin-right: 5px;
}

.hed_right .hed_contwrap a {
  background: #e95a33;
  padding: 20px;
  display: block;
  color: #ffffff;
  font-size: 20px;
}

.header {
  background: #3abcae;
}

#header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header {
    justify-content: center;
  }
}

#header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 15px;
}

#header #siteID {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

.top_txt {
  background: #319f2d;
  color: #ffffff;
  font-size: 0.8em;
  text-align: left;
}

@media only screen and (max-width: 834px) {
  .top_txt {
    padding: 10px;
  }
}

.top_txt .inner {
  padding: 0 10px !important;
}

.hedTopwrap {
  padding: 25px 0;
}

#hed_info .address {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  #hed_info .address {
    text-align: center;
  }
}

.navwrap {
  padding: 0 !important;
}

@media screen and (min-width: 834px) and (max-width: 1540px) {
  .navwrap {
    width: 100%;
  }
}

.hedwrap {
  border-bottom: 5px solid #eed757;
}

#nav_global {
  width: 100%;
  padding: 10px 0;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li:last-of-type {
  border-right: 1px dotted #ffffff;
}

#nav_global ul li {
  z-index: 1;
  width: 100%;
  border-left: 1px dotted #ffffff;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li {
    font-size: 14px;
  }
  #nav_global ul li br {
    display: block !important;
  }
}

#nav_global ul li br {
  display: none;
}

#nav_global ul li span {
  display: block;
}

#nav_global ul li a {
  width: 100%;
  display: block;
  color: #ffffff;
  padding: 10px;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#nav_global ul ul li {
  border-bottom: 1px dashed #eed757;
}

#nav_global ul ul li a {
  color: #333333;
}

.mainwrap .main_title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 1495px;
  min-width: 240px;
  height: 400px;
  display: none;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_title {
    height: 160px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_title {
    height: 280px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_title img {
    width: 40% !important;
  }
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_title img {
    width: 30% !important;
  }
}

.mainwrap .main_txt {
  color: #ffffff;
  font-size: 1.8em;
  font-weight: bold;
  padding-top: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    font-size: 1.2em;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    font-size: 1em;
    padding-top: 15px;
  }
}

.mainArea {
  width: 100%;
}

.mainArea img {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual h1 {
  color: #301E0C;
  font-size: 7em;
  z-index: 1;
  position: relative;
  text-align: center;
  font-family: 'Lobster', cursive;
  text-shadow: 2px 2px 0px #fff;
  padding: 180px 0;
  line-height: 0.9em;
  background: rgba(255, 255, 255, 0.3);
}

#local-keyvisual h1:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    padding: 80px 0;
    font-size: 5em;
  }
}

#local-keyvisual h1 span {
  display: block;
  font-size: 20px;
  padding-top: 3em;
  text-shadow: none;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 500;
  line-height: 1em;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding-top: 1em;
  }
}

.confirm {
  margin-top: 100px;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

#local-keyvisual-title {
  background-size: cover;
}

#mainArea2 #local-keyvisual-title {
  background: url(../images/contents/blog_local.jpg) center no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

html, body {
  min-height: 100%;
}

#wrap {
  height: 100%;
  padding: 72px 10px 0;
  overflow-x: hidden;
  position: relative;
}

.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.onlineShops {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 92px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-size: cover;
}

.header .orangeTypeLink,
.header .tipsLink,
.header .aboutLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: no-repeat right top;
  background-size: auto auto;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  letter-spacing: 0.2em;
  -webkit-transition: width .5s ease;
  transition: width .5s ease;
  position: absolute;
}

.header .orangeTypeLink span,
.header .tipsLink span,
.header .aboutLink span {
  display: block;
  margin-right: 14px;
}

.header .orangeTypeLink:hover,
.header .tipsLink:hover,
.header .aboutLink:hover {
  width: 57px;
}

.header .orangeTypeLink {
  height: 149px;
  width: 47px;
  color: #fff;
  background-image: url(../img/common/bg_header-menu01.png);
  top: 25px;
  left: -28px;
}

.header .tipsLink {
  height: 159px;
  width: 47px;
  background-image: url(../img/common/bg_header-menu02.png);
  top: 184px;
  left: -28px;
}

.header .aboutLink {
  height: 151px;
  width: 48px;
  color: #fff;
  background-image: url(../img/common/bg_header-menu03.png);
  top: 358px;
  left: -14px;
}

.headerLogo {
  margin: 0 18px 0 auto;
  width: 113px;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 62px;
  width: 10%;
  background-size: contain;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #fff;
  border: 2px solid #eed757;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 12%;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 2px;
  background-color: #e95a33;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: 0;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 50%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  background: url(../images/common/body_pt.gif) center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  padding: 50px 0 !important;
}

.overlayMenu > .outer .hed_right {
  background: none !important;
  position: inherit;
  padding: 10px;
  border-top: none;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
  .overlayMenu > .outer .spcont_btn_wrap {
    margin-top: 30px;
  }
  .overlayMenu > .outer .spcont_btn_wrap a {
    padding: 0 !important;
  }
  .overlayMenu > .outer .spcont_btn_wrap img {
    width: 60% !important;
  }
}

.overlayMenu > .outer li {
  position: relative;
}

.overlayMenu > .outer li a {
  padding: 15px;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 65px;
  opacity: 1;
  transition: none;
}

.overlayMenu > .outer li a:hover {
  color: #fff !important;
}

.overlayMenu > .outer li a:before {
  position: absolute;
  font-family: "Font Awesome 5 pro";
  font-size: 1.5em;
  /*サイズ*/
  left: 15px;
  /*アイコンの位置*/
  color: #333333;
  /*アイコン色*/
}

.overlayMenu > .outer li a:hover:before {
  color: #fff !important;
}

.overlayMenu > .outer li:nth-of-type(1) a {
  color: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(1) a:hover {
  background: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(1) a:before {
  content: "\f4c9";
  /*アイコンのユニコード*/
  color: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(2) a {
  color: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(2) a:hover {
  background: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(2) a:before {
  content: "\f51c";
  /*アイコンのユニコード*/
  color: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(3) a {
  color: #eed757;
}

.overlayMenu > .outer li:nth-of-type(3) a:hover {
  background: #eed757;
}

.overlayMenu > .outer li:nth-of-type(3) a:before {
  content: "\f1ae";
  /*アイコンのユニコード*/
  color: #eed757;
}

.overlayMenu > .outer li:nth-of-type(4) a {
  color: #0068B7;
}

.overlayMenu > .outer li:nth-of-type(4) a:hover {
  background: #0068B7;
}

.overlayMenu > .outer li:nth-of-type(4) a:before {
  content: "\f004";
  /*アイコンのユニコード*/
  color: #0068B7;
}

.overlayMenu > .outer li:nth-of-type(5) a {
  color: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(5) a:hover {
  background: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(5) a:before {
  content: "\f70c";
  /*アイコンのユニコード*/
  color: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(6) a {
  color: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(6) a:hover {
  background: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(6) a:before {
  content: "\f4c6";
  /*アイコンのユニコード*/
  color: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(7) a {
  color: #eed757;
}

.overlayMenu > .outer li:nth-of-type(7) a:hover {
  background: #eed757;
  color: #eed757;
}

.overlayMenu > .outer li:nth-of-type(7) a:before {
  content: "\f0c0";
  /*アイコンのユニコード*/
  color: #eed757;
}

.overlayMenu > .outer li:nth-of-type(8) a {
  color: #0068B7;
}

.overlayMenu > .outer li:nth-of-type(8) a:hover {
  background: #0068B7;
}

.overlayMenu > .outer li:nth-of-type(8) a:before {
  content: "\f059";
  /*アイコンのユニコード*/
  color: #0068B7;
}

.overlayMenu > .outer li:nth-of-type(9) a {
  color: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(9) a:hover {
  background: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(9) a:before {
  content: "\f756";
  /*アイコンのユニコード*/
  color: #3abcae;
}

.overlayMenu > .outer li:nth-of-type(10) a {
  color: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(10) a:hover {
  background: #e95a33;
}

.overlayMenu > .outer li:nth-of-type(10) a:before {
  content: "\f05a";
  /*アイコンのユニコード*/
  color: #e95a33;
}

@media only screen and (max-width: 640px) {
  .sp_menu {
    padding: 20px 0;
    justify-content: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sp_menu {
    justify-content: center;
  }
}

.sp_menu ul li {
  text-align: center;
}

.sp_menu ul li a {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px dotted #eed757;
}

.sp_menu .inner {
  padding: 0;
}

.spmenu_h2 {
  margin: 30px 67px 10px;
}

.spmenu_h2 h2 {
  font-size: 2.8em;
  font-family: 'Lobster', cursive;
  color: #3abcae;
  padding: 10px;
  text-shadow: 1px 1px 0px #000;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

.overlayMenu .onlineShops {
  margin-top: 42px;
}

.overlayMenu .corporateLink {
  margin-top: 10px;
  height: 55px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background: url(../img/common/bg_corporate-site.jpg) no-repeat center;
  background-size: cover;
}

.overlayMenuTitle {
  display: block;
  margin-top: 64px;
  font-size: 21px;
  font-weight: bold;
  color: #016411;
  text-align: center;
  letter-spacing: 0.1em;
  position: relative;
}

.overlayMenuTitle:after {
  content: "";
  margin: auto;
  height: 22px;
  width: 100px;
  background-size: contain;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.overlayMenuList {
  margin-top: 64px;
  font-size: 16px;
  font-weight: bold;
  color: #016411;
  letter-spacing: 0.1em;
}

.overlayMenuList li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: no-repeat right center;
}

.overlayMenuList li + li {
  margin-top: 22px;
}

.overlayMenuList li:after {
  content: "";
  margin: 0 40px 0 1em;
  background: url(../img/common/bg_dots01.png) repeat-x left center;
  background-size: 3px auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.overlayMenuList li:nth-of-type(2) {
  background-image: url(../img/common/img_menu-orange01.png);
  background-size: auto 23px;
}

.overlayMenuList li:nth-of-type(1),
.overlayMenuList li:nth-of-type(3) {
  background-image: url(../img/common/img_menu-orange02.png);
  background-size: 22.5px 19.5px;
}

.overlayMenuList li:nth-of-type(4) {
  background-image: url(../img/common/img_menu-orange03.png);
  background-size: 21px 25px;
}

.copyright {
  padding: 28px 0;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.topIntroPicture {
  margin: 50px auto 0;
  width: 287px;
}

.topIntroCatch {
  display: block;
  font-size: 21px;
  font-weight: bold;
  color: #016411;
  line-height: 2.95;
  letter-spacing: 0.1em;
  text-align: center;
}

.topIntroCatch span {
  position: relative;
}

.topIntroCatch span:before {
  content: "";
  height: 131px;
  width: 270px;
  background: url(../img/top/bg_intro-catch.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 36px;
  left: -41px;
}

.topIntroText {
  margin: 32px 14px 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 2.15;
  letter-spacing: 0.05em;
}

.orangeTypes {
  margin-top: 36px;
  padding: 16px 28px 32px;
  position: relative;
}

.orangeTypes:before, .orangeTypes:after {
  content: "";
  z-index: 2;
  position: absolute;
}

.orangeTypes:before {
  border: solid 3px #016411;
  top: 8px;
  right: 10px;
  bottom: 0;
  left: 10px;
}

.orangeTypes:after {
  border: solid 2px #016411;
  top: 14px;
  right: 16px;
  bottom: 6px;
  left: 16px;
}

.orangeTypes .decoration:before, .orangeTypes .decoration:after {
  content: "";
  background: no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
}

.orangeTypes .decoration:before {
  height: 192px;
  width: 130px;
  background-image: url(../img/types/bg_top-left.png);
  left: 0;
}

.orangeTypes .decoration:after {
  height: 203px;
  width: 152px;
  background-image: url(../img/types/bg_top-right.png);
  right: 0;
}

.orangeTypes .inner {
  padding-top: 180px;
  z-index: 3;
  position: relative;
}

.orangeTypes .moreButton {
  display: block;
  margin-top: 30px;
  padding-top: 16px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  background: url(../img/common/ico_bottom.svg) no-repeat center bottom 15px, url(../img/types/bg_orange-more.png) no-repeat center;
  background-size: 22px auto, cover;
  height: 65px;
}

.orangeTypesTitle {
  color: #016411;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
}

.orangeTypesTitle span {
  position: relative;
}

.orangeTypesTitle span:before {
  content: "";
  height: 135px;
  width: 181px;
  background: url(../img/top/img_types-title.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -94px;
  left: -8px;
}

.orangeTypesList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.orangeTypesList li {
  position: relative;
}

.orangeTypesList a {
  display: block;
  height: 100%;
  width: 100%;
}

.orangeTypesList .title {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #016411;
  text-align: center;
  position: absolute;
  top: 26px;
  left: 0;
}

.orangeTypesList figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.orangeTypesList img {
  margin-bottom: 23px;
  width: auto;
}

.citrusHeading {
  margin-top: 15px;
  text-align: center;
  position: relative;
}

.citrusHeading span {
  display: inline-block;
  margin-top: 132px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #016411;
  letter-spacing: 0.1em;
  position: relative;
}

.citrusHeading span:before {
  content: "";
  margin: auto;
  height: 29px;
  width: 30px;
  background: url(../img/types/img_heading-orange.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.citrusHeading span:after {
  content: "";
  height: 5px;
  width: 100%;
  background: url(../img/common/img_waves.svg) repeat-x center;
  background-size: auto 100%;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.citrusHeading h1 {
  margin-top: 28px;
  font-size: 25px;
  color: #016411;
  letter-spacing: 0.1em;
}

.citrusHeading small {
  margin-left: 10px;
  font-family: "Cabin Sketch", cursive;
  font-size: 12px;
  vertical-align: middle;
  letter-spacing: .1em;
}

.citrusHeading:before, .citrusHeading:after {
  content: "";
  background: no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
}

.citrusHeading:before {
  height: 192px;
  width: 130px;
  background-image: url(../img/types/bg_top-left.png);
  left: -12px;
}

.citrusHeading:after {
  height: 203px;
  width: 152px;
  background-image: url(../img/types/bg_top-right.png);
  right: -11px;
}

.citrusData {
  margin: 22px 0 34px;
}

.citrusContents {
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.05em;
}

.citrusContents > * + * {
  margin-top: 25px;
}

.citrusContents hr {
  border: solid 2px #f0ede6;
}

.citrusFigure {
  text-align: center;
}

.citrusFigure figure {
  display: inline-block;
  max-width: 682px;
}

.citrusFigure figcaption {
  margin-top: 7px;
  font-size: 12px;
}

.citrusHeading01 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.77;
  letter-spacing: 0.1em;
}

.citrusHeading02 {
  padding: 9px 1em 9px 47px;
  line-height: 1.6;
  font-size: 20px;
  letter-spacing: 0.1em;
  border-top: solid 2px #f0ede6;
  border-bottom: solid 2px #f0ede6;
  background: url(../img/types/img_heading-orange.png) no-repeat left 5px center;
  background-size: auto 29px;
}

.citrusHeading03 {
  line-height: 1;
}

.citrusHeading03 span {
  display: inline-block;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #016411;
  line-height: 1.6;
  letter-spacing: 0.1em;
  position: relative;
}

.citrusHeading03 span:after {
  content: "";
  height: 5px;
  width: 100%;
  background: url(../img/common/img_waves.svg) repeat-x left top;
  background-size: 119px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.citrusCalendarHeading {
  margin-top: 48px;
  text-align: center;
  position: relative;
}

.citrusCalendarHeading h2 {
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.24em;
}

.citrusCalendarHeading img {
  display: inline-block;
  margin-top: 8px;
  width: 105px;
}

.citrusCalendarHeading:after {
  content: "";
  height: 76px;
  width: 57px;
  background: url(../img/types/img_calendar-girl.png) no-repeat center;
  background-size: contain;
  z-index: 1;
  position: absolute;
  bottom: -33px;
  left: 0;
}

.citrusCalendar {
  margin: 30px -10px 0;
  padding: 40px 15px 20px;
  background: url(../img/types/bg_calendar.jpg) repeat left top;
  background-size: 100% auto;
}

.citrusCalendar .moreButton {
  height: 60px;
  width: 100%;
  background: url(../img/types/bg_calendar-more.jpg) no-repeat center;
  background-size: cover;
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #fff;
}

.citrusCalendarList {
  overflow: hidden;
  -webkit-transition: height .8s ease;
  transition: height .8s ease;
}

.citrusCalendarList li {
  margin-bottom: 50px;
  position: relative;
}

.citrusCalendarList li:after {
  content: "";
  height: 1px;
  width: 100%;
  background: url(../img/types/bg_calendar-border.png) repeat-x left top;
  background-size: auto 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.citrusCalendarList dt {
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1em;
}

.citrusCalendarList dt img {
  width: auto;
  margin-right: 16px;
  vertical-align: middle;
}

.citrusCalendarList dt span {
  vertical-align: middle;
}

.citrusCalendarList dd {
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.85;
  letter-spacing: 0.05em;
}

.citrusSeason .text {
  display: inline-block;
  width: 86px;
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.citrusSeason .label {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  width: 135px;
  letter-spacing: 0.05em;
  line-height: 40px;
  text-align: center;
  background-color: #fdb100;
  color: #fff;
  border-radius: 2px;
}

.citrusHeading04 {
  line-height: 1;
}

.citrusHeading04 span {
  display: inline-block;
  padding: 3px 10px;
  background: url(../img/types/bg_dl01.jpg) no-repeat center;
  background-size: cover;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.1em;
}

.citrusDl02 dt {
  padding: 12px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: url(../img/types/bg_dl02.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}

.citrusDl02 dt:before {
  content: "";
  border: solid 2px #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.citrusIngredient {
  margin-top: 62px;
}

.citrusIngredientHeading {
  margin-bottom: 10px;
  padding-left: 70px;
  font-size: 18px;
  letter-spacing: 0.1em;
  position: relative;
}

.citrusIngredientHeading:before {
  content: "";
  height: 87px;
  width: 60px;
  background: url(../img/types/ico_measure.png) no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: -11px;
  left: 9px;
}

.citrusIngredientList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 15px;
  background-color: #f0ede6;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.1em;
}

.citrusIngredientList dt, .citrusIngredientList dd {
  padding: 18px 0;
}

.citrusIngredientList dt {
  width: 54.2372881%;
  text-align: center;
  color: #fff;
  background: no-repeat center;
  background-size: cover;
}

.citrusIngredientList dd {
  padding-left: 15px;
  width: 45.7627118644%;
  background-color: #fff;
}

.purchaseButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 40px auto 0;
  height: 60px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background: url(../img/common/ico_next-white.svg) no-repeat right 13px center, url(../img/types/bg_purchase-btn.jpg) no-repeat center;
  background-size: auto 22px, cover;
  letter-spacing: 0.1em;
  max-width: 325px;
}

.citrusRecipeHeading {
  font-size: 20px;
  letter-spacing: 0.1em;
  text-align: center;
  position: relative;
}

.citrusRecipeHeading:after {
  content: "";
  margin: auto;
  height: 32px;
  width: 189px;
  background: url(../img/types/bg_recipe-text.png);
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: -33px;
  left: 0;
}

.citrusRecipeHeading .color01 {
  color: #52a50a;
}

.citrusRecipeHeading .color02 {
  color: #ffb700;
}

.citrusRecipeHeading .color03 {
  color: #008fb6;
}

.citrusRecipe {
  margin-top: 62px;
}

.sideTipsHead {
  margin: 48px auto 0;
  height: 151px;
  width: 260px;
  background: url(../img/common/tit_side-mikan-tips.png) no-repeat center;
  background-size: contain;
  position: relative;
}

.sideTipsHead span {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 63px;
  left: 21px;
  letter-spacing: 0.1em;
}

.sideTipsList {
  margin: 20px auto 0;
  width: 260px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.54;
  letter-spacing: 0.1em;
}

.sideTipsList li {
  height: 55px;
  background: url(../img/common/bg_side-tips.png) no-repeat center;
  background-size: contain;
}

.sideTipsList a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 1em;
  height: 100%;
  width: 100%;
}

.sideTipsList li + li {
  margin-top: 10px;
}

.sideTipsMore {
  display: inline-block;
  margin: 20px 0 0 10px;
  padding-left: 30px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
  -webkit-transition: transform .5s ease;
  transition: transform .5s ease;
}

.sideTipsMore:before {
  content: "";
  height: 19px;
  width: 12px;
  background: url(../img/common/ico_next-black.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 12px;
}

.sideTipsMore:hover {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.orangeTips {
  margin: 76px 15px 0;
}

.orangeTipsTitle {
  height: 192px;
  padding: 34px 0 0 14px;
  background: url(../img/top/bg_tips.png) no-repeat center;
  background-size: cover;
  position: relative;
}

.orangeTipsTitle .heading {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
}

.orangeTipsTitle .heading:before {
  content: "";
  height: 36px;
  width: 226px;
  background: url(../img/top/img_tips-heading.png) no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: -55px;
  left: 0;
}

.orangeTipsTitle .more {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: absolute;
  bottom: 25px;
  left: 38px;
}

.orangeTipsTitle .more:before {
  content: "";
  height: 22px;
  width: 13px;
  background: url(../img/common/ico_next-black.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -2px;
  left: -23px;
}

.orangeTipsTitle:before {
  content: "";
  height: 57px;
  width: 111px;
  background: url(../img/top/img_tips-hat.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -46px;
  right: 5px;
}

.orangeTipsList {
  margin-top: 20px;
}

.orangeTipsList li {
  height: 65px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  background: url(../img/tips/bg_tips-item02.png) no-repeat left center, url(../img/tips/bg_tips-item01.jpg) no-repeat center;
  background-size: 32px 27px,cover;
}

.orangeTipsList a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 43px;
}

.tipsArchiveHead {
  margin-top: 60px;
  padding-top: 52px;
  height: 186px;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  background: url(../img/tips/bg_tips-title.png) no-repeat center;
  background-size: cover;
  position: relative;
}

.tipsArchiveHead:before, .tipsArchiveHead:after {
  content: "";
  position: absolute;
}

.tipsArchiveHead:before {
  margin: auto;
  height: 57px;
  width: 111px;
  background: url(../img/tips/img_title-hat.png) no-repeat center;
  background-size: contain;
  top: -46px;
  left: 0;
  right: 0;
}

.tipsArchiveHead:after {
  margin: auto;
  height: 36px;
  width: 226px;
  background: url(../img/tips/img_sub-title.png) no-repeat center;
  background-size: contain;
  top: 100px;
  left: 0;
  right: 0;
}

.tipsArchiveList {
  margin-top: 25px;
}

.tipsArchiveList .title {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.64;
  letter-spacing: 0.1em;
  position: relative;
}

.tipsArchiveList .title:after {
  content: "";
  height: 2px;
  background: url(../img/tips/bg_title-dots.png) repeat-x left top;
  background-size: auto 100%;
  position: absolute;
  left: 0;
  bottom: -5px;
  right: 0;
}

.tipsArchiveList .excerpt {
  margin-top: 20px;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.05em;
}

.tipsArchiveList li {
  min-height: 210px;
  background: url(../img/tips/bg_tips-item03.png) no-repeat left center, url(../img/tips/bg_tips-item01.png) no-repeat center;
  background-size: 29px 107px, cover;
}

.tipsArchiveList a {
  display: block;
  height: 100%;
  width: 100%;
  padding: 28px 20px 22px 48px;
}

.orangeTipArticle {
  margin-top: 25px;
}

.orangeTipArticle .articleTitle {
  padding: 22px 22px 22px 43px;
  background: url(../img/tips/bg_tips-item02.png) no-repeat left center, url(../img/tips/bg_tips-item01.jpg) no-repeat center;
  background-size: 32px 27px, cover;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.orangeTipArticle .articleContent {
  margin-top: 25px;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.05em;
}

.orangeTipArticle .articleContent > * + * {
  margin-top: 15px;
}

.orangeTipArticle .articleContent hr {
  margin: 30px 0;
  border: solid 2px #f0ede6;
}

.orangeTipArticle .articlePicture {
  text-align: center;
}

.orangeTipArticle .articlePicture figure {
  display: inline-block;
  max-width: 682px;
}

.orangeTipArticle .articlePicture figcaption {
  margin-top: 15px;
  font-size: 12px;
  line-height: 1.91;
  letter-spacing: 0.05em;
}

.articlePagination {
  margin-top: 42px;
  padding-top: 42px;
  height: 82px;
  text-align: center;
  position: relative;
}

.articlePagination .next,
.articlePagination .prev {
  position: absolute;
  top: 0;
}

.articlePagination .next img,
.articlePagination .prev img {
  height: 22px;
  width: auto;
}

.articlePagination .next {
  right: 0;
}

.articlePagination .prev {
  left: 0;
}

.articlePagination .backToIndex {
  display: inline-block;
  width: 182px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  position: relative;
}

.articlePagination .backToIndex:after {
  content: "";
  height: 12px;
  width: 182px;
  background: url(../img/tips/bg_back-to-index.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 27px;
  left: 0;
}

.tipsArchivePagination {
  margin-top: 36px;
  height: 82px;
  font-family: 'Cabin Sketch', cursive;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.tipsArchivePagination .page,
.tipsArchivePagination .current {
  display: inline-block;
  width: 35px;
  font-size: 18px;
}

.tipsArchivePagination .current {
  position: relative;
}

.tipsArchivePagination .current:after {
  content: "";
  margin: auto;
  height: 16px;
  width: 35px;
  background: url(../img/tips/ico_current.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: -17px;
  left: 0;
}

.tipsArchivePagination .previouspostslink,
.tipsArchivePagination .nextpostslink {
  font-size: 16px;
  letter-spacing: 0.1em;
  position: absolute;
  bottom: 0;
}

.tipsArchivePagination .previouspostslink:before,
.tipsArchivePagination .nextpostslink:before {
  content: "";
  height: 22px;
  width: 13px;
  background: no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
}

.tipsArchivePagination .page + .page,
.tipsArchivePagination .current + .page,
.tipsArchivePagination .page + .current {
  margin-left: 20px;
}

.tipsArchivePagination .previouspostslink {
  padding-left: 30px;
  left: 0;
}

.tipsArchivePagination .previouspostslink:before {
  background-image: url(../img/common/ico_prev-black.svg);
  left: 0;
}

.tipsArchivePagination .nextpostslink {
  padding-right: 30px;
  right: 0;
}

.tipsArchivePagination .nextpostslink:before {
  background-image: url(../img/common/ico_next-black.svg);
  right: 0;
}

.topIntroPicture,
.topIntroCatch,
.topIntroText {
  -webkit-transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, transform .5s ease;
  transition: opacity .5s ease, transform .5s ease, -webkit-transform .5s ease;
  opacity: 0;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.topIntroPicture[data-emergence="visible"],
.topIntroCatch[data-emergence="visible"],
.topIntroText[data-emergence="visible"] {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

@media screen and (max-width: 767px) {
  #wrap {
    height: 100%;
    overflow: hidden;
  }
  .footer .contents {
    margin-top: 38px;
  }
  .citrusSeason {
    margin-top: 14px;
  }
  .orangeTypesList {
    margin-top: 35px;
  }
  .orangeTypesList li {
    height: 168px;
    width: calc(50% - 2px);
    position: relative;
  }
  .orangeTypesList li:nth-of-type(2n):before {
    content: "";
    margin-left: 2px;
    width: 2px;
    background-color: #016411;
    position: absolute;
    top: 10px;
    left: -1px;
    bottom: 10px;
  }
  .orangeTypesList li:nth-of-type(2n + 1):not(:first-of-type):before {
    content: "";
    margin-left: 2px;
    height: 3px;
    width: calc(200%);
    background-color: #016411;
    z-index: 1;
    position: absolute;
    top: -1.5px;
    left: 0;
  }
  .citrusDl01 dd,
  .citrusDl02 dd {
    margin-top: 14px;
  }
  .citrusDl02 dt:nth-of-type(n + 2) {
    margin-top: 18px;
  }
  .citrusIngredientList dt:nth-of-type(n + 2), .citrusIngredientList dd:nth-of-type(n + 2) {
    margin-top: 10px;
  }
  .orangeTipsList li + li {
    margin-top: 10px;
  }
  .tipsArchiveList li + li {
    margin-top: 20px;
  }
}

@media screen and (min-width: 834px), print {
  #wrap {
    min-width: 1288px;
    max-width: 1346px;
    margin: 0 auto;
    padding: 20px 56px 0 20px;
  }
  #wrap:before, #wrap:after {
    content: "";
    width: 28px;
    position: absolute;
    right: 56px;
  }
  #wrap:after {
    background: url(../img/common/bg_header02_pc.png) repeat-y left top;
    background-size: 100% auto;
    top: 25px;
    bottom: 99px;
  }
  #wrap:before {
    height: 10px;
    background: url(../img/common/bg_header01_pc.png) no-repeat left top;
    top: 15px;
  }
  .mainContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 40px auto 0;
    width: 1173px;
  }
  .mainContainer .sideNav {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 50px;
    width: 280px;
  }
  .mainContainer .mainContents {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .sideOrangesHead {
    margin: 40px auto 0;
    height: 54px;
    line-height: 54px;
    width: 260px;
    background: url(../img/common/bg_orange-types.png) no-repeat center;
    background-size: cover;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
  }
  .sideOranges {
    margin: 23px auto 0;
    width: 260px;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05em;
  }
  .sideOranges li {
    -webkit-transition: transform .5s ease;
    transition: transform .5s ease;
  }
  .sideOranges li:hover {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  .sideOranges img {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
  .sideOranges li + li {
    margin-top: 20px;
  }
  .button {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
  }
  .button:hover {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  .topIntroPicture {
    margin-top: 82px;
    width: 539px;
  }
  .topIntroCatch {
    margin-top: 38px;
    font-size: 26px;
  }
  .topIntroCatch span:before {
    height: 96px;
    width: 534px;
    background-image: url(../img/top/bg_intro-catch_pc.png);
    position: absolute;
    top: 39px;
    left: -159px;
  }
  .topIntroCatch span:after {
    content: "";
    height: 152px;
    width: 941px;
    background: url(../img/top/img_intro-clouds.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: -90px;
    left: -350px;
  }
  .topIntroText {
    margin-top: 48px;
    text-align: center;
    line-height: 2.46;
    letter-spacing: 0.1em;
  }
  .orangeTypes {
    margin: 68px auto 0;
    padding: 0 61px 69px 61px;
    width: 1060px;
  }
  .orangeTypes:before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .orangeTypes:after {
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
  }
  .orangeTypes .decoration:before {
    height: 268px;
    width: 308px;
    background-image: url(../img/types/bg_top-left_pc.png);
    top: -31px;
    left: -28px;
  }
  .orangeTypes .decoration:after {
    height: 288px;
    width: 303px;
    background-image: url(../img/types/bg_top-right_pc.png);
    top: -34px;
    right: -28px;
  }
  .orangeTypesList {
    margin-top: 88px;
  }
  .orangeTypesList li {
    height: 195px;
    width: calc(100% / 5);
  }
  .orangeTypesList li + li:not(:nth-of-type(5n + 1)):before {
    content: "";
    height: 175px;
    width: 2px;
    background: #0e6613;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: -1px;
  }
  .orangeTypesList li:nth-of-type(5n + 1):not(:first-of-type):before {
    content: "";
    height: 3px;
    width: 938px;
    background: #0e6613;
    z-index: 1;
    position: absolute;
    top: -1.5px;
  }
  .orangeTypesList a:after {
    content: "";
    margin: auto;
    height: 88px;
    width: 81px;
    background: url(../img/types/ico_more.png) no-repeat center;
    background-size: contain;
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: opacity .5s ease, -webkit-transform .5s ease;
    transition: opacity .5s ease, -webkit-transform .5s ease;
    transition: transform .5s ease, opacity .5s ease;
    transition: transform .5s ease, opacity .5s ease, -webkit-transform .5s ease;
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .orangeTypesList a:hover:after {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  .orangeTypesTitle {
    font-size: 28px;
  }
  .orangeTypesTitle span:before {
    height: 156px;
    width: 191px;
    background-image: url(../img/top/img_types-title_pc.png);
    top: -102px;
    left: -5px;
  }
  .citrusCalendarList li {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 134px;
  }
  .citrusCalendarList li:after {
    bottom: 0;
  }
  .citrusCalendarList dl {
    padding-right: 40px;
    width: 342px;
  }
  .citrusCalendarList dt {
    margin: 15px 0px 0 8px;
  }
  .citrusCalendarList dt img {
    margin-right: 18px;
  }
  .citrusCalendarList dd {
    line-height: 2;
  }
  .citrusSeason {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 400px;
  }
  .citrusSeason .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 11px;
    line-height: 1.27;
    letter-spacing: 0.05em;
  }
  .citrusIngredientList {
    width: 843px;
    padding: 50px;
  }
  .citrusIngredientList dt, .citrusIngredientList dd {
    padding: 0;
    height: 50px;
    line-height: 50px;
  }
  .citrusIngredientList dt:nth-of-type(n + 3), .citrusIngredientList dd:nth-of-type(n + 3) {
    margin-top: 10px;
  }
  .citrusIngredientList dt {
    width: 180px;
    background-image: url(../img/types/bg_ingredient01.jpg);
  }
  .citrusIngredientList dt:nth-of-type(2n) {
    margin-left: 10px;
  }
  .citrusIngredientList dt:nth-of-type(4n - 1), .citrusIngredientList dt:nth-of-type(4n - 2) {
    background-image: url(../img/types/bg_ingredient02.jpg);
  }
  .citrusIngredientList dd {
    padding-left: 24px;
    width: 186px;
  }
  .citrusCalendar {
    margin: 93px 0 0;
    padding: 30px 50px 60px;
    background-image: url(../img/types/bg_calendar_pc.jpg);
    position: relative;
  }
  .citrusCalendarList {
    height: auto !important;
  }
  .citrusCalendarList .citrus01 .citrusSeason .label {
    width: 120px;
  }
  .citrusCalendarList .citrus02 .citrusSeason .label {
    width: 60px;
  }
  .citrusCalendarList .citrus03 .citrusSeason .label {
    width: 200px;
  }
  .citrusCalendarList .citrus04 .citrusSeason .label {
    margin-left: 60px;
    width: 80px;
  }
  .citrusCalendarList .citrus05 .citrusSeason .label {
    margin-left: 60px;
    width: 80px;
  }
  .citrusCalendarList .citrus06 .citrusSeason .label {
    margin-left: 70px;
    width: 130px;
  }
  .citrusCalendarList .citrus07 .citrusSeason .label {
    margin-left: 100px;
    width: 160px;
  }
  .citrusCalendarList .citrus08 .citrusSeason .label {
    margin-left: 80px;
    width: 80px;
  }
  .citrusCalendarList .citrus09 .citrusSeason .label {
    margin-left: 80px;
    width: 90px;
  }
  .citrusCalendarList .citrus10 .citrusSeason .label {
    margin-left: 80px;
    width: 120px;
  }
  .citrusCalendarList .citrus11 .citrusSeason .label {
    margin-left: 80px;
    width: 200px;
  }
  .citrusCalendarList .citrus12 .citrusSeason .label {
    margin-left: 100px;
    width: 70px;
  }
  .citrusCalendarList .citrus13 .citrusSeason .label {
    margin-left: 120px;
    width: 100px;
  }
  .citrusCalendarList .citrus14 .citrusSeason .label {
    margin-left: 140px;
    width: 80px;
  }
  .citrusCalendarList .citrus15 .citrusSeason .label {
    margin-left: 160px;
    width: 60px;
  }
  .citrusCalendarList .citrus16 .citrusSeason .label {
    margin-left: 190px;
    height: 50px;
    width: 50px;
  }
  .citrusCalendarList .citrus17 .citrusSeason .label {
    margin-left: 190px;
    height: 50px;
    width: 50px;
  }
  .citrusCalendarList .citrus18 .citrusSeason .label {
    margin-left: 180px;
    width: 120px;
  }
  .citrusCalendarList .citrus19 .citrusSeason .label {
    margin-left: 260px;
    width: 80px;
  }
  .citrusCalendarList .citrus20 .citrusSeason .label {
    margin-left: 340px;
    width: 60px;
  }
  .citrusSeason .text {
    display: none;
  }
  .citrusDl01 dd {
    margin-top: 14px;
  }
  .citrusDl02 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .citrusDl02 dt:nth-of-type(n + 2), .citrusDl02 dd:nth-of-type(n + 2) {
    margin-top: 30px;
  }
  .citrusDl02 dt {
    margin-right: 30px;
    height: auto;
    width: 220px;
  }
  .citrusDl02 dd {
    width: 593px;
  }
  .citrusCalendarHeading h2 {
    font-size: 28px;
  }
  .citrusCalendarHeading:after {
    height: 95px;
    width: 79px;
  }
  .citrusCalendarHeading:after {
    bottom: -97px;
    left: 150px;
  }
  .citrusHeading span {
    font-size: 20px;
  }
  .citrusHeading h1 {
    font-size: 28px;
  }
  .citrusHeading:before {
    height: 234px;
    width: 204px;
    background-image: url(../img/types/bg_top-left02_pc.png);
    left: 0;
  }
  .citrusHeading:after {
    height: 219px;
    width: 231px;
    background-image: url(../img/types/bg_top-right02_pc.png);
    right: 0;
  }
  .citrusData {
    margin-bottom: 82px;
  }
  .citrusCalendarScale {
    position: absolute;
    top: -46px;
    right: 65px;
  }
  .citrusRecipeHeading {
    font-size: 26px;
  }
  .citrusRecipeHeading .inner {
    display: inline-block;
    width: 398px;
    padding-left: 5px;
    text-align: left;
  }
  .citrusRecipeHeading:after {
    height: 43px;
    width: 398px;
    background-image: url(../img/types/bg_recipe-text_pc.png);
    bottom: -12px;
    left: 0;
  }
  .citrusRecipe {
    margin-top: 50px;
    text-align: center;
  }
  .orangeTips {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 105px auto 0;
    width: 1060px;
  }
  .orangeTipsTitle {
    margin-right: 42px;
    padding: 57px 0 0 41px;
    height: 300px;
    width: 329px;
    background-image: url(../img/top/bg_tips_pc.png);
    background-size: contain;
  }
  .orangeTipsTitle:before {
    left: 10px;
    right: auto;
  }
  .orangeTipsTitle .heading {
    font-size: 26px;
  }
  .orangeTipsTitle .heading:before {
    bottom: -49px;
  }
  .orangeTipsTitle .more {
    font-size: 14px;
    bottom: 112px;
    left: 67px;
    transition: transform .5s ease;
  }
  .orangeTipsTitle .more:hover {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  .orangeTipsList {
    -ms-flex-item-align: start;
    align-self: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 689px;
  }
  .orangeTipsList li {
    height: 75px;
    width: 330px;
  }
  .orangeTipsList li:nth-of-type(2n) {
    margin-left: 29px;
  }
  .orangeTipsList li:nth-of-type(n + 3) {
    margin-top: 30px;
  }
  .orangeTipsList a {
    padding-left: 48px;
    padding-right: 1em;
  }
  .tipsArchiveList {
    width: 843px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .tipsArchiveList li {
    min-height: 185px;
    width: 406px;
  }
  .tipsArchiveList li:nth-of-type(2n) {
    margin-left: 30px;
  }
  .tipsArchiveList li:nth-of-type(n + 3) {
    margin-top: 30px;
  }
  .articlePagination {
    margin-top: 62px;
    padding: 0;
  }
  .articlePagination .prev,
  .articlePagination .next {
    top: 12px;
  }
  .tipsArchivePagination {
    margin-top: 75px;
    height: auto;
  }
  .tipsArchivePagination .page,
  .tipsArchivePagination .current {
    font-size: 20px;
  }
  .tipsArchivePagination .page + .page,
  .tipsArchivePagination .current + .page,
  .tipsArchivePagination .page + .current {
    margin-left: 26px;
  }
  .orangeTipsList li,
  .sideTipsList li,
  .tipsArchiveList li {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
  }
  .orangeTipsList li:hover,
  .sideTipsList li:hover,
  .tipsArchiveList li:hover {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

.orangeTipsList span {
  display: block;
  width: 100%;
}

#wpadminbar {
  top: auto !important;
  bottom: 0;
}

#wpadminbar .ab-sub-wrapper {
  bottom: 100%;
}

.orangeTypesList {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .orangeTypesList {
    height: auto !important;
  }
}

@media screen and (max-width: 767px) {
  #wpadminbar {
    display: none !important;
  }
}

@media screen and (max-width: 375px) {
  .toggleMenu {
    width: 51.73vw;
  }
}

@media screen and (max-width: 767px) {
  .footer .onlineShops li,
  .overlayMenu .onlineShops li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
  }
  .footer .onlineShops li + li,
  .overlayMenu .onlineShops li + li {
    margin-left: 10px;
  }
  .footer .onlineShops .button,
  .overlayMenu .onlineShops .button {
    width: 100%;
    text-align: center;
  }
  .footer .onlineShops .onlineShopsList,
  .overlayMenu .onlineShops .onlineShopsList {
    padding-left: 22px;
    padding-right: 22px;
  }
  .orangeTypesList {
    -webkit-transition: height .8s ease;
    transition: height .8s ease;
  }
}

.decoBorder {
  /* height: 100%; */
  /* width: 10px; */
  /* background: url(../img/common/bg.jpg) repeat left top; */
  /* background-size: 100% auto; */
  /* background-size: 100vw auto; */
  /* z-index: 9999; */
  /* position: fixed; */
  /* top: 0; */
  left: 0;
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(58, 188, 174, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mvTop,
.mvBottom,
.mvOranges,
.mvGirl,
.mvSupervised {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mvTop {
  background: url(../img/top/mv_top.png) no-repeat center top;
  background-size: 100% auto;
  z-index: 1;
}

.mvLogo {
  margin-top: 132px;
  margin-top: 35.2vw;
}

.mvLogo strong {
  margin-top: 12px;
  margin-top: 3.2vw;
  display: block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}

.mvSupervised {
  background: url(../img/top/mv_supervised.png) no-repeat left top;
  background-size: 100% auto;
  z-index: 3;
}

.mvGirl {
  z-index: 1;
  background: url(../img/top/mv_girl.png) no-repeat center bottom 0;
  background-size: 100% auto;
}

.mvOranges {
  background: url(../img/top/mv_oranges.png) no-repeat center bottom 0;
  background-size: 100% auto;
}

.mvBottom {
  background: url(../img/top/mv_bottom.png) no-repeat center bottom 0;
  background-size: 100% auto;
}

.mvScroll {
  margin: auto;
  height: 30px;
  width: 90px;
  background-color: #fff;
  z-index: 5;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.mvScroll img {
  display: block;
  margin: 0 auto;
  width: 55px;
}

.mvScroll:after {
  content: "";
  margin: auto;
  height: 12px;
  width: 2px;
  background-color: #016411;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 834px) {
  .mainVisual {
    height: calc(100vh - 20px);
  }
  .mvTop {
    background-image: url(../img/top/mv_top_pc.png);
  }
  .mvGirl {
    background-image: url(../img/top/mv_girl_pc.png);
  }
  .mvOranges {
    background-image: url(../img/top/mv_oranges_pc.png);
  }
  .mvBottom {
    background-image: url(../img/top/mv_bottom_pc.png);
  }
  .mvLogo {
    margin-top: 184px;
    margin-top: 23.9583vh;
  }
  .mvLogo strong {
    margin-top: 15px;
    font-size: 14px;
  }
  .mvSupervised {
    margin-top: 244px;
    margin-top: 31.77vh;
    background-image: url(../img/top/mv_supervised_pc.png);
  }
  .mvScroll {
    height: 63px;
    width: 102px;
  }
  .mvScroll:after {
    height: 35px;
  }
}

@media screen and (max-width: 375px) {
  .mvLogo {
    margin: 0 auto;
    width: 90%;
    margin-top: 32.8125vw;
  }
  .citrusCalendarHeading h2 {
    font-size: 6.4vw;
  }
}

@media screen and (orientation: landscape) and (max-width: 767px) {
  .mainVisual {
    height: calc(100vh - 72px);
  }
  .mvTop {
    background-image: url(../img/top/mv_top_pc.png);
  }
  .mvGirl {
    background-image: url(../img/top/mv_girl_pc.png);
  }
  .mvOranges {
    background-image: url(../img/top/mv_oranges_pc.png);
  }
  .mvBottom {
    background-image: url(../img/top/mv_bottom_pc.png);
  }
  .mvLogo {
    width: 50%;
    margin: 184px auto 0;
    margin: 23.9583vh auto 0;
  }
  .mvLogo strong {
    margin-top: 15px;
    font-size: 14px;
  }
  .mvSupervised {
    margin-top: 122px;
    margin-top: 15.88vh;
    background-image: url(../img/top/mv_supervised_pc.png);
  }
}

.orangeIcon {
  display: inline-block;
  height: 23px;
  width: 35px;
}

.notFound {
  padding-top: 150px;
  padding-top: 40vw;
  color: #0e6613;
  font-weight: bold;
  position: relative;
}

.notFound .heading {
  text-align: center;
}

.notFound .text1 {
  margin-top: 28px;
  margin-top: 7.46vw;
  display: block;
  font-size: 17px;
  text-align: center;
  line-height: 1.82;
  letter-spacing: 0.1em;
}

.notFound .text2 {
  margin-top: 21px;
  margin-top: 5.6vw;
  font-size: 13px;
  line-height: 2.15;
  letter-spacing: 0.05em;
}

.notFound:before, .notFound:after {
  content: "";
  background: no-repeat center;
  background-size: contain;
  position: absolute;
}

.notFound:before {
  height: 192px;
  height: 51.2vw;
  width: 130px;
  width: 34.66vw;
  background-image: url(../img/404/bg_top-left.png);
  top: 14px;
  left: -12px;
}

.notFound:after {
  height: 203px;
  height: 54.13vw;
  width: 152px;
  width: 40.53vw;
  background-image: url(../img/404/bg_top-right.png);
  top: 16px;
  right: -11px;
}

.buttonWrapper {
  position: relative;
}

.notFound .button {
  display: flex;
  margin: 100px auto 0;
  height: 60px;
  font-size: 13px;
  letter-spacing: 0.1em;
  max-width: 325px;
  color: #fff;
  background: url(../img/common/ico_next-white.svg) no-repeat right 13px center, url(../img/404/bg_btn.jpg) no-repeat center;
  background-size: auto 22px, cover;
}

.notFound .buttonGirl {
  height: 106px;
  width: 99px;
  background: url(../img/404/img_404-girl.png) no-repeat center;
  background-size: contain;
  margin: auto;
  -webkit-transition: transform .5s ease;
  transition: transform .5s ease;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
  position: absolute;
  left: 0;
  bottom: 100%;
  right: 0;
}

@media screen and (max-width: 767px) {
  .notFound .heading img {
    height: 36px;
    height: 9.6vw;
    width: auto;
  }
}

@media screen and (min-width: 768px), print {
  .mainContainer.errorPage .sideNav {
    margin-right: 35px;
  }
  .notFound {
    width: 843px;
    padding-top: 130px;
  }
  .notFound .text1 {
    margin-top: 50px;
    font-size: 20px;
  }
  .notFound .text2 {
    text-align: center;
    line-height: 2.46;
    letter-spacing: 0.1em;
  }
  .notFound:before {
    height: 204px;
    width: 234px;
    background-image: url(../img/404/bg_top-left_pc.png);
    top: -2px;
    left: 0;
  }
  .notFound:after {
    height: 219px;
    width: 231px;
    background-image: url(../img/404/bg_top-right_pc.png);
    top: -4px;
    right: 0;
  }
  .notFound .button {
    margin-top: 111px;
    height: 54px;
    width: 310px;
    font-size: 14px;
  }
  .notFound .buttonWrapper .button:hover + .buttonGirl {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: currentColor;
  text-decoration: none;
}

img {
  max-width: 100%;
  border: none;
  vertical-align: top;
}

ul,
ol {
  list-style: none;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

.fixed_cont_menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(58, 188, 174, 0.7);
  padding: 10px;
  z-index: 1;
  border-top: 5px solid #3abcae;
}

.fixed_cont_menu .pccont_btn_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap {
    padding: 0;
  }
}

.fixed_cont_menu .pccont_btn_wrap li {
  margin: 0 5px;
}

.fixed_cont_menu .pccont_btn_wrap li img {
  width: 70% !important;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap li {
    margin: 0 5px;
  }
  .fixed_cont_menu .pccont_btn_wrap li img {
    width: 100% !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
}

footer#global_footer .inner {
  padding: 30px 0;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer .ft_nav {
  margin-top: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li {
  text-align: left;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 2px 10px;
  display: block;
  color: #333333;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #ffffff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.footer2 {
  padding: 60px 10px 20px;
  background: url(../images/common/access_bg.png);
  text-align: center;
  border-top: 5px solid #bce4e7;
  font-size: 14px;
}

@media only screen and (max-width: 834px) {
  .footer2 {
    padding: 30px 10px;
  }
}

.footer2 .adress_wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .footer2 .adress_wrap {
    display: block;
  }
}

.footer2 .adress_wrap .flxL {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  .footer2 .adress_wrap .flxL {
    text-align: center;
  }
}

.footer2 .adress_wrap .flxL .ftlogo {
  margin-bottom: 20px;
}

.footer2 .adress_wrap .flxL .ftshop p {
  font-size: 1.25em;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .footer2 .adress_wrap .flxR {
    margin-top: 15px;
  }
}

.footer2 .access_h201 h2 {
  font-size: 1.25em;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 5px;
}

.footer2 .access_h201 h2:before {
  content: url(../images/common/moyori_icon.png);
  margin-right: 15px;
}

.footer2 .access_h201 h2:after {
  content: "";
  flex-grow: 1;
  border-top: 4px dotted #3abcae;
  display: block;
  margin-left: 15px;
}

.footer2 .access_h202 h2 {
  font-size: 1.25em;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 15px 0 5px;
}

.footer2 .access_h202 h2:before {
  content: url(../images/common/train_icon.png);
  margin-right: 15px;
}

.footer2 .access_h202 h2:after {
  content: "";
  flex-grow: 1;
  border-top: 4px dotted #3abcae;
  display: block;
  margin-left: 15px;
}

.footer2 .access_h203 h2 {
  font-size: 1.25em;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 15px 0 5px;
}

.footer2 .access_h203 h2:before {
  content: url(../images/common/car_icon.png);
  margin-right: 15px;
}

.footer2 .access_h203 h2:after {
  content: "";
  flex-grow: 1;
  border-top: 4px dotted #3abcae;
  display: block;
  margin-left: 15px;
}

.footer2 .access_h204 h2 {
  font-size: 1.25em;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 15px 0 5px;
}

.footer2 .access_h204 h2:before {
  content: url(../images/common/bus_icon.png);
  margin-right: 15px;
}

.footer2 .access_h204 h2:after {
  content: "";
  flex-grow: 1;
  border-top: 4px dotted #3abcae;
  display: block;
  margin-left: 15px;
}

.footer2 .access_table dl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 0;
}

@media only screen and (max-width: 834px) {
  .footer2 .access_table dl {
    display: block;
  }
}

.footer2 .access_table dt {
  text-align: center;
  background: #eed757;
  border-radius: 10px;
  padding: 5px;
  color: #ffffff;
  width: 10%;
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .footer2 .access_table dt {
    width: 30%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .access_table dt {
    width: 20%;
  }
}

.footer2 .access_table dd {
  text-align: left;
  width: 88%;
}

@media only screen and (max-width: 834px) {
  .footer2 .access_table dd {
    width: 100%;
  }
}

.footer2 .access_list {
  margin-left: 20px;
}

.footer2 .access_list li {
  text-align: left;
  list-style: disc;
}

.footer2 .chuui {
  background: #fff;
  padding: 30px;
  text-align: left;
  border-radius: 10px;
  margin-top: 50px;
}

.footer2 .chuui .access_h3 h3 {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px dotted #eed757;
  padding-bottom: 5px;
}

.footer2 .chuui .access_h3 h3 i {
  color: #eed757;
  margin-right: 7px;
}

.footer2 .ft_cont_wrap {
  background: rgba(255, 255, 255, 0.5);
  padding: 70px 0 !important;
}

@media only screen and (max-width: 834px) {
  .footer2 .ft_cont_wrap {
    padding: 70px 10px !important;
  }
}

.footer2 .fth2 {
  margin-bottom: 30px;
}

.footer2 .fth2 h2 {
  font-size: 1.8em;
  position: relative;
  color: #333333;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.footer2 .fth2 h2:before {
  content: url(../images/common/cont_icon.png);
  display: block;
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .footer2 .fth2 h2 {
    font-size: 26px;
  }
}

.footer2 .ft_txt {
  color: #333333;
  margin-bottom: 30px;
}

.footer2 .tel_cont_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .footer2 .tel_cont_wrap {
    display: block;
  }
}

.footer2 .tel_cont_wrap .ft_cont a {
  background: #e95a33;
  padding: 10px 0;
  display: block;
  color: #ffffff;
  font-size: 1.25em;
  width: 250px;
  margin: 0 auto;
}

.footer2 .fttelwrap {
  margin-right: 50px;
}

@media only screen and (max-width: 640px) {
  .footer2 .fttelwrap {
    margin-right: 0;
    width: 250px;
    margin: 0 auto;
  }
}

.footer2 .fttelwrap .fttel_txt {
  color: #ffffff;
}

.footer2 .fttelwrap .tel2 {
  margin-bottom: 5px;
  font-family: "Times New Roman";
}

.footer2 .fttelwrap .tel2 a {
  color: #333333;
}

.footer2 .fttelwrap .tel2 p {
  font-size: 25px;
}

.footer2 .fttelwrap .tel2 p a {
  color: #333333;
}

.footer2 .fttelwrap .fttel_txt {
  color: #333333;
  border-top: 1px solid #333;
}

#copy {
  color: #ffffff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  font-size: 60px;
}

.pagetop a {
  color: #e95a33;
  padding: 10px;
  display: block;
  background: #fff;
  border: 2px solid #eed757;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 11%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .pagetop {
    bottom: 11%;
  }
}

#footer .ftlogo {
  margin-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
    text-align: center;
  }
}

.ft_top_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .ft_top_inner {
    display: block;
  }
}

.ft_R {
  text-align: left;
}

.ft_R .address {
  margin-top: 10px;
}

@media only screen and (max-width: 834px) {
  .ft_R .address {
    text-align: center;
    margin-top: 15px;
  }
}

.ft_R .tel2 a {
  color: #333333;
}

.ft_L {
  text-align: center;
  color: #333333;
  line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
  .ft_L {
    text-align: center;
  }
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_L {
    padding-left: 10px;
  }
}

.ft_nav {
  display: flex;
  justify-content: center;
}

.small_bg {
  background: #3abcae;
  position: relative;
  z-index: -1;
  margin-top: 80px;
}

.small_bg:before {
  content: url(../images/common/small_bg.png);
  position: absolute;
  top: -260%;
  right: 0;
  left: 0;
  z-index: -1;
}

.ftmap iframe {
  width: 100%;
  height: 250px;
}

/* box */
.flex {
  display: block !important;
}

@media only screen and (max-width: 640px) {
  .flex img {
    width: 1005 !important;
  }
}

.top_list {
  background: #fff;
  padding: 30px;
  border: 2px solid #e95a33;
}

.top_list li {
  position: relative;
  padding-left: 1.2em;
  /*アイコン分のスペース*/
  line-height: 1.4;
  margin: 10px 0;
  font-size: 1.2em;
  border-bottom: 2px solid #eed757;
  padding: 10px;
}

.top_list li:before {
  content: '・';
  color: #e95a33;
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: inherit !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: center;
}

.instagram div.sns_list > div {
  width: 23% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 47% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 30% !important;
    margin: 5px;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 280px !important;
  height: 280px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.h2_01 {
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .h2_01 {
    transform: none;
  }
}

.h2_01 h2 {
  font-size: 3.1em;
  color: #333333;
  line-height: 1em;
  font-family: 'Lobster', cursive;
  color: #e95a33;
  text-shadow: 1px 1px 0px #000;
}

.h2_01 h2:before {
  content: url(../images/common/midashi_icon.png);
  display: block;
}

.h2_01 h2 span {
  font-size: 18px;
  font-weight: 500;
  display: block;
  text-shadow: none;
  color: #333333;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .h2_01 h2 span {
    font-size: 16px;
  }
}

.h2_02 {
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .h2_02 {
    transform: none;
  }
}

.h2_02 h2 {
  font-size: 3.1em;
  color: #333333;
  line-height: 1em;
  font-family: 'Lobster', cursive;
  color: #3abcae;
  text-shadow: 1px 1px 0px #000;
  position: relative;
  display: inline-block;
  width: 100%;
}

.h2_02 h2 span {
  font-size: 18px;
  font-weight: 500;
  display: block;
  text-shadow: none;
  color: #333333;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.h2_02 h2:before, .h2_02 h2:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 37%;
  height: 6px;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2:before, .h2_02 h2:after {
    width: 30%;
  }
}

.h2_02 h2:before {
  left: 0;
}

.h2_02 h2:after {
  right: 0;
}

.h2_03 h2 {
  font-size: 3.1em;
  color: #333333;
  line-height: 1em;
  font-family: 'Lobster', cursive;
  color: #e95a33;
  text-shadow: 1px 1px 0px #000;
  position: relative;
}

.h2_03 h2 span {
  font-size: 18px;
  font-weight: 500;
  display: block;
  text-shadow: none;
  color: #333333;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.img_bnr {
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .img_bnr {
    transform: none;
  }
}

.img_bnr img {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.txt {
  line-height: 2em;
}

.imgbox {
  justify-content: center;
  text-align: left;
}

.imgbox .box {
  margin: 10px;
  width: 31%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transform: scale(0) rotate(-30deg);
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    transform: none;
  }
}

.imgbox .box .name {
  background: #fff;
  padding: 5px;
  text-align: center;
  font-size: 1.25em;
  font-weight: bold;
}

.imgbox .box .tantou {
  background: #fff;
  padding: 5px;
  text-align: center;
  font-size: 13px;
}

.imgbox .box img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 834px) {
  .imgbox .box img {
    width: 100% !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .imgbox .box img {
    width: 100% !important;
  }
}

.imgbox .box h3 {
  text-align: center;
  padding: 10px;
}

.imgbox .box article > div {
  display: none;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.fadeindown {
  -webkit-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.0s;
  -ms-animation-duration: 1.0s;
  animation-duration: 1.0s;
  -webkit-animation-name: fadeindown;
  animation-name: fadeindown;
  visibility: visible !important;
}

@-webkit-keyframes fadeindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.imgbox2 {
  justify-content: center;
}

.imgbox2 .box {
  width: 30%;
  margin: 10px;
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100% !important;
  }
}

.imgbox2 .box img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.imgbox2 .box h3 {
  display: none;
}

.imgbox2 .box article div {
  text-align: center;
  margin: 5px;
}

#taiken {
  margin-top: -100px;
  padding-top: 100px;
}

.imgbox3 {
  justify-content: center;
}

.imgbox3 .box {
  width: 22%;
  margin: 10px;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .imgbox3 .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox3 .box {
    width: 46%;
  }
}

.imgbox3 .box img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  width: 280px !important;
  height: 280px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  .imgbox3 .box img {
    height: auto !important;
  }
}

.imgbox3 .box h3 {
  display: none;
}

.imgbox3 .box article div {
  text-align: center;
  margin: 5px;
}

.btnbox {
  justify-content: space-between;
}

.btnbox .box {
  margin: 0 10px;
  width: 22%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(0) rotate(-30deg);
  border-radius: 10px;
}

@media only screen and (max-width: 640px) {
  .btnbox .box {
    width: 46% !important;
    margin: 10px;
    transform: none;
  }
}

.btnlist li {
  background: #e95a33;
  padding: 10px;
  border-radius: 10px;
  width: 31%;
  margin: 0 10px;
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    width: 100%;
    margin: 5px;
  }
}

.btnlist a {
  color: #ffffff;
  display: block;
}

@media only screen and (max-width: 640px) {
  .btnbox2 {
    justify-content: center;
  }
}

.btnbox2 .box {
  margin: 0 10px;
  width: 30%;
  transform: scale(0) rotate(-30deg);
}

.btnbox2 .box img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 640px) {
  .btnbox2 .box {
    width: 46% !important;
    margin: 10px;
    transform: none;
  }
}

.btn a {
  color: #ffffff;
  font-size: 1.25em;
  padding: 15px;
  display: block;
  width: 260px;
  font-weight: 500;
  background: #e95a33;
  position: relative;
  margin: 0 auto;
}

.btn a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 10%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #ffffff;
  /*アイコン色*/
}

.sec_01 {
  background: rgba(188, 228, 231, 0.8);
  padding: 130px 0 30px;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .sec_01 .txt {
    padding: 10px;
  }
  .sec_01 .txt br {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    padding: 60px 0 30px;
  }
}

.sec_01:after {
  content: "";
  background: url(../images/common/cont1_bg.png) repeat-x;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  bottom: -100%;
  width: 100%;
  overflow: hidden;
  -moz-animation: infinity-loop 30s infinite linear 1s both;
  -webkit-animation: infinity-loop 30s infinite linear 1s both;
  animation: infinity-loop 30s infinite linear 1s both;
  z-index: -1;
}

@-webkit-keyframes infinity-loop {
  from {
    background-position: 0  0;
  }
  to {
    background-position: -1920px 0;
  }
}

@keyframes infinity-loop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1920px 0;
  }
}

@media only screen and (max-width: 834px) {
  .newswrap {
    margin: 0 10px;
  }
}

.newswrap .news {
  padding-left: 30px;
  height: 300px;
  overflow-y: auto;
  background: url(../images/common/news_bg.png) no-repeat center;
  font-size: 14px;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    margin-top: 30px;
    padding-left: 0;
  }
}

.newswrap .news dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
}

.newswrap .news dd {
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
}

.newswrap .news dl {
  display: block !important;
  border-bottom: 1px dashed #3abcae;
  padding: 10px;
}

.newswrap2 {
  background: #fff;
  padding: 40px;
  border: 5px solid #3abcae;
  border-radius: 10px;
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .newswrap2 {
    transform: none;
  }
}

.fb {
  text-align: center;
}

.fb iframe {
  height: 300px;
}

.blog_list {
  border: none !important;
  flex-direction: inherit !important;
}

@media only screen and (max-width: 640px) {
  .blog_list {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.blog_list .detail {
  margin-top: 0 !important;
}

.blog_list .blog_photo {
  width: 100% !important;
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .blog_list .blog_photo {
    transform: none;
  }
}

.blog_list .blog_photo img {
  width: 100% !important;
}

.blog_list .blog_text {
  width: 100% !important;
  font-size: 13px !important;
}

@media only screen and (max-width: 834px) {
  .blog_list .blog_text {
    display: block !important;
  }
}

.blog_list .blog_text h3 {
  width: 80%;
  font-size: 16px !important;
}

.blog_list .blog_text h3 a {
  color: #e95a33 !important;
}

.blog_list .blog_date {
  color: #333333 !important;
}

div.blog_list > div {
  border: none !important;
  display: block !important;
}

@media only screen and (max-width: 640px) {
  div.blog_list > div {
    flex-direction: inherit !important;
    width: 50% !important;
  }
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.dl_kids01 {
  padding: 30px;
  background: #fff;
  border: 2px solid #eed757;
  max-width: 600px;
  margin: 0 auto;
}

.dl_kids01 dl {
  border-bottom: 4px dotted #e95a33;
  padding: 10px 0;
}

.dl_kids01 dt {
  border: none !important;
  font-weight: bold;
  background: #eed757 !important;
  flex-direction: inherit !important;
}

.dl_kids01 dd {
  border: none !important;
}

.dl_kids02 {
  background: #fff;
  padding: 30px;
  border: 2px solid #bce4e7;
  max-width: 600px;
  margin: 0 auto;
}

.dl_kids02 dl {
  border-bottom: 4px dotted #eed757;
  padding: 10px 0;
}

.dl_kids02 dt {
  border: none !important;
  font-weight: bold;
  background: #bce4e7 !important;
  flex-direction: inherit !important;
}

.dl_kids02 dd {
  border: none !important;
}

.taiken_wrap {
  background: rgba(238, 215, 87, 0.8);
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.txt_back {
  padding: 0 10px !important;
}

.txt_back .pink {
  display: flex;
  align-items: center;
}

.txt_back .pink:before {
  content: '';
  width: 100px;
  height: 20px;
  background: #FCD9F1;
  display: inline-block;
  margin-right: 10px;
}

.txt_back .blue {
  display: flex;
  align-items: center;
}

.txt_back .blue:before {
  content: '';
  width: 100px;
  height: 20px;
  background: #C1EDF0;
  display: inline-block;
  margin-right: 10px;
}

.td_point {
  background: #fff;
  border-radius: 50px;
  padding: 30px !important;
  width: 45%;
  margin: 10px;
}

@media only screen and (max-width: 834px) {
  .td_point {
    width: 100%;
    padding: 30px;
    margin: 30px 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .td_point {
    width: 100%;
    padding: 30px;
  }
}

.td_point td {
  border: none !important;
  font-size: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point td {
    font-size: 1em;
  }
}

.td_point .point_midashi {
  font-size: 1.5em;
  font-weight: bold;
  color: #F07D60;
  position: relative;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point .point_midashi {
    font-size: 1.25em;
  }
}

.td_point .point_midashi:before {
  content: url(../images/common/point01.png);
  position: absolute;
  left: -4%;
  top: -248%;
}

.td_point .point_midashi2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #3ABCAE;
  position: relative;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point .point_midashi2 {
    font-size: 1.25em;
  }
}

.td_point .point_midashi2:before {
  content: url(../images/common/point02.png);
  position: absolute;
  left: -4%;
  top: -248%;
}

@media only screen and (max-width: 640px) {
  .td_point .point_midashi2:before {
    top: -331%;
  }
}

.td_point .point_midashi3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #0068B7;
  position: relative;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point .point_midashi3 {
    font-size: 1.25em;
  }
}

.td_point .point_midashi3:before {
  content: url(../images/common/point03.png);
  position: absolute;
  left: -4%;
  top: -85%;
}

.td_point2 {
  background: #fff;
  border-radius: 50px;
  padding: 30px !important;
  margin: 10px;
}

@media only screen and (max-width: 834px) {
  .td_point2 {
    width: 100%;
    padding: 30px;
    margin: 30px 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .td_point2 {
    width: 100%;
    padding: 30px;
  }
}

.td_point2 td {
  border: none !important;
  font-size: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point2 td {
    font-size: 1em;
  }
}

.td_point2 .td_L {
  width: 70%;
}

.td_point2 .td_R {
  width: 30%;
}

.td_point2 .point_midashi {
  font-size: 1.5em;
  font-weight: bold;
  color: #F07D60;
  position: relative;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point2 .point_midashi {
    font-size: 1.25em;
  }
}

.td_point2 .point_midashi:before {
  content: url(../images/common/point01.png);
  position: absolute;
  left: -4%;
  top: -248%;
}

.td_point2 .point_midashi2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #3ABCAE;
  position: relative;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point2 .point_midashi2 {
    font-size: 1.25em;
  }
}

.td_point2 .point_midashi2:before {
  content: url(../images/common/point02.png);
  position: absolute;
  left: -4%;
  top: -248%;
}

@media only screen and (max-width: 640px) {
  .td_point2 .point_midashi2:before {
    top: -331%;
  }
}

.td_point2 .point_midashi3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #0068B7;
  position: relative;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .td_point2 .point_midashi3 {
    font-size: 1.25em;
  }
}

.td_point2 .point_midashi3:before {
  content: url(../images/common/point03.png);
  position: absolute;
  left: -4%;
  top: -85%;
}

.pointbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pointbox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pointwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  background: #3abcae;
  padding: 30px;
  color: #ffffff;
  border: 1px solid #eed757;
}

.pointwrap li {
  border-bottom: 1px dotted #fff;
  padding: 15px 0;
}

@media only screen and (max-width: 640px) {
  .pointwrap {
    display: block;
  }
}

.h3_taiken h3 {
  font-size: 1.25em;
  background: #3abcae;
  color: #ffffff;
  padding: 10px;
}

.txt {
  line-height: 2em;
}

.h2_04 {
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .h2_04 {
    transform: none;
  }
}

.h2_04 h2 {
  background-color: #e95a33;
  /* 背景色 */
  color: #fff;
  /* 文字色 */
  overflow: hidden;
  padding: 10px 15px;
  /* 余白 */
  position: relative;
  font-size: 1.8em;
  line-height: 1.7em;
  border-radius: 10px;
  text-shadow: 1px 1px 0px #000;
}

@media only screen and (max-width: 640px) {
  .h2_04 h2 {
    font-size: 1.7em;
  }
}

.h2_04 h2 br {
  display: none;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 br {
    display: block;
  }
}

.h2_04 h2 span {
  font-family: 'Lobster', cursive;
  margin-left: 15px;
  text-shadow: 1px 1px 0px #000;
  color: #eed757;
}

.h2_04 h2:before {
  background-color: #fff;
  /* 切り替わる色 */
  content: '';
  display: block;
  opacity: 0.6;
  /* 不透明度 */
  transform: rotate(-30deg);
  position: absolute;
  bottom: -30px;
  right: -100px;
  width: 200px;
  height: 200px;
}

.h2_05 {
  transform: scale(0) rotate(-30deg);
}

@media only screen and (max-width: 640px) {
  .h2_05 {
    transform: none;
  }
}

.h2_05 br {
  display: none;
}

.h2_05 br:nth-of-type(2) {
  display: block;
}

@media only screen and (max-width: 640px) {
  .h2_05 br:nth-of-type(2) {
    display: block;
  }
}

.h2_05 h2 {
  color: #3abcae;
  padding: 0.40em;
  font-size: 2em;
  font-weight: bold;
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 1.2em;
  text-shadow: 1px 1px 0px #000;
}

.h2_05 h2 br {
  display: none;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 br {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 1.7em;
  }
}

.h2_05 h2 span {
  font-family: 'Lobster', cursive;
}

.h2_06 {
  transform: scale(0) rotate(-30deg);
  padding: 0 10px !important;
}

@media only screen and (max-width: 640px) {
  .h2_06 {
    transform: none;
  }
}

.h2_06 h2 {
  font-size: 3.1em;
  color: #ffffff;
  line-height: 1em;
  font-family: 'Lobster', cursive;
  text-shadow: 1px 1px 0px #3abcae;
  position: relative;
  display: inline-block;
  width: 100%;
}

.h2_06 h2 span {
  font-size: 18px;
  font-weight: 500;
  display: block;
  text-shadow: none;
  color: #333333;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.h2_06 h2:before, .h2_06 h2:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 30%;
  height: 6px;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
}

@media only screen and (max-width: 834px) {
  .h2_06 h2:before, .h2_06 h2:after {
    width: 15%;
  }
}

.h2_06 h2:before {
  left: 0;
}

.h2_06 h2:after {
  right: 0;
}

.h2_kids01 h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #e95a33;
  position: relative;
}

.h2_kids01 h2:before {
  content: url(../images/common/midashi2_icon.png);
  position: absolute;
  left: -6%;
  top: -115%;
}

.h3_01 h3 {
  border-bottom: 2px solid #bce4e7;
  padding-bottom: 5px;
  font-size: 1.25em;
}

.h3_rental h3 {
  border-bottom: 2px solid #3abcae;
  padding-bottom: 5px;
  font-size: 1.25em;
}

.h3_02 h3 {
  font-size: 1.25em;
  border-bottom: 2px dotted #eed757;
  padding-bottom: 5px;
}

.h3_02 h3 span {
  color: #e95a33;
  font-size: 1.5em;
  font-family: 'Lobster', cursive;
  text-shadow: 1px 1px 0px #000;
}

.h3_03 h3 {
  position: relative;
  line-height: 1.4;
  padding: 0.25em 1em;
  display: inline-block;
  top: 0;
}

.h3_03 h3:before, .h3_03 h3:after {
  position: absolute;
  top: 0;
  content: '';
  width: 8px;
  height: 100%;
  display: inline-block;
}

.h3_03 h3:before {
  border-left: solid 1px black;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  left: 0;
}

.h3_03 h3:after {
  content: '';
  border-top: solid 1px black;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  right: 0;
}

.h3_04 h3 {
  font-size: 1.25em;
  background: #3abcae;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
}

.txt_waku {
  background: #bce4e7;
  padding: 30px;
  border: 1px solid #3abcae;
}

.txt_waku h3 {
  font-size: 1.25em;
  font-weight: bold;
  color: #e95a33;
  margin-bottom: 15px;
}

.txt_waku2 {
  background: #e95a33;
  padding: 30px;
  border: 1px solid #eed757;
  color: #ffffff;
}

.txt_waku2 .btn {
  background: #3abcae;
  color: #ffffff;
  padding: 10px;
  display: inline-block;
  border-radius: 50px;
  width: 300px;
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
}

.txt_waku2 h3 {
  font-size: 1.25em;
  font-weight: bold;
  color: #eed757;
  margin-bottom: 15px;
}

.yoyaku_txt div {
  font-size: 1.5em;
  font-weight: bold;
  background: #e95a33;
  color: #ffffff;
  padding: 30px;
}

.healthwrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .healthwrap {
    display: block;
  }
  .healthwrap .flxL {
    width: 100% !important;
  }
  .healthwrap .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.healthwrap .flxL {
  width: 30%;
}

.healthwrap .flxR {
  width: 68%;
}

.kidswrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .kidswrap {
    display: block;
  }
  .kidswrap .flxL {
    width: 100% !important;
  }
  .kidswrap .flxR {
    width: 100% !important;
  }
}

.kidswrap .flxL {
  width: 30%;
}

.kidswrap .flxR {
  width: 68%;
}

.kidswrap2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(242, 214, 59, 0.6);
  padding: 30px;
  border: 1px solid #e95a33;
}

@media only screen and (max-width: 834px) {
  .kidswrap2 {
    display: block;
  }
  .kidswrap2 .flxL {
    width: 100% !important;
  }
  .kidswrap2 .flxR {
    width: 100% !important;
  }
}

.kidswrap2 .flxL {
  width: 26%;
  text-align: center;
}

.kidswrap2 .flxR {
  width: 70%;
}

.kidswrap3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #C8E8EB;
  padding: 30px;
  border: 1px solid #e95a33;
}

@media only screen and (max-width: 640px) {
  .kidswrap3 {
    display: block;
  }
  .kidswrap3 .flxL {
    width: 100% !important;
  }
  .kidswrap3 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.kidswrap3 .flxL {
  width: 26%;
}

.kidswrap3 .flxR {
  width: 70%;
}

.pwrap_kids {
  padding: 30px;
  background: #0068B7;
}

.pwrap_kids .cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .pwrap_kids .cont2 {
    display: block;
  }
  .pwrap_kids .cont2 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap_kids .cont2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap_kids a {
  color: #ffffff;
}

.pwrap_kids a:hover {
  text-decoration: underline;
}

.pwrap_kids .midashi_p {
  border-bottom: 2px solid #e95a33;
  padding-bottom: 5px;
}

.pwrap_kids .list_partner {
  background: #F2D63B;
  padding: 30px;
}

.pwrap_kids .flxL {
  width: 30%;
}

.pwrap_kids .flxR {
  width: 68%;
}

.pwrap2_kids {
  background: #F2D63B;
  padding: 30px;
}

.pwrap2_kids .cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .pwrap2_kids .cont2 {
    display: block;
  }
  .pwrap2_kids .cont2 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap2_kids .cont2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap2_kids a {
  color: #333333;
}

.pwrap2_kids a:hover {
  text-decoration: underline;
}

.pwrap2_kids .midashi_p {
  border-bottom: 2px solid #3abcae;
  padding-bottom: 5px;
}

.pwrap2_kids .list_partner {
  background: #E95A33;
  padding: 30px;
  color: #fff;
}

.pwrap2_kids .flxL {
  width: 30%;
}

.pwrap2_kids .flxR {
  width: 68%;
}

.pwrap3_kids {
  background: #3ABCAE;
  padding: 30px;
  color: #ffffff;
}

.pwrap3_kids .cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .pwrap3_kids .cont2 {
    display: block;
  }
  .pwrap3_kids .cont2 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap3_kids .cont2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap3_kids a {
  color: #ffffff;
}

.pwrap3_kids a:hover {
  text-decoration: underline;
}

.pwrap3_kids .midashi_p {
  border-bottom: 2px solid #eed757;
  padding-bottom: 5px;
}

.pwrap3_kids .list_partner {
  background: #0068B7;
  padding: 30px;
}

.pwrap3_kids .flxL {
  width: 30%;
}

.pwrap3_kids .flxR {
  width: 68%;
}

.pwrap4_kids {
  background: #E95A33;
  padding: 30px;
  color: #ffffff;
}

.pwrap4_kids .cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .pwrap4_kids .cont2 {
    display: block;
  }
  .pwrap4_kids .cont2 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap4_kids .cont2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap4_kids a {
  color: #ffffff;
}

.pwrap4_kids a:hover {
  text-decoration: underline;
}

.pwrap4_kids .midashi_p {
  border-bottom: 2px solid #7CBDE0;
  padding-bottom: 5px;
}

.pwrap4_kids .list_partner {
  background: #C8E8EB;
  padding: 30px;
  color: #333;
}

.pwrap4_kids .flxL {
  width: 30%;
}

.pwrap4_kids .flxR {
  width: 68%;
}

.list_partner1 li, .list_partner2 li, .list_partner3 li, .list_partner4 li, .list_partner5 li, .list_partner6 li, .list_partner7 li {
  margin: 5px 0;
}

.list_partner1 .back, .list_partner2 .back, .list_partner3 .back, .list_partner4 .back, .list_partner5 .back, .list_partner6 .back, .list_partner7 .back {
  background: #bce4e7;
  border-radius: 5px;
  padding: 5px;
  color: #333333;
  width: 200px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.midashi_p {
  font-size: 1.25em;
  font-weight: bold;
}

.pwrap {
  padding: 30px;
  background: #0068B7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .pwrap {
    display: block;
  }
  .pwrap .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap a {
  color: #DC143C;
}

.pwrap a:hover {
  text-decoration: underline;
}

.pwrap .midashi_p {
  border-bottom: 2px solid #e95a33;
  padding-bottom: 5px;
}

.pwrap .list_partner {
  background: #F2D63B;
  padding: 30px;
}

.pwrap .flxL {
  width: 30%;
}

.pwrap .flxR {
  width: 68%;
}

.pwrap2 {
  background: #F2D63B;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .pwrap2 {
    display: block;
  }
  .pwrap2 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap2 a {
  color: #DC143C;
}

.pwrap2 a:hover {
  text-decoration: underline;
}

.pwrap2 .midashi_p {
  border-bottom: 2px solid #3abcae;
  padding-bottom: 5px;
}

.pwrap2 .list_partner {
  background: #E95A33;
  padding: 30px;
  color: #fff;
}

.pwrap2 .flxL {
  width: 30%;
}

.pwrap2 .flxR {
  width: 68%;
}

.pwrap3 {
  background: #3ABCAE;
  padding: 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .pwrap3 {
    display: block;
  }
  .pwrap3 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap3 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap3 a {
  color: #DC143C;
}

.pwrap3 a:hover {
  text-decoration: underline;
}

.pwrap3 .midashi_p {
  border-bottom: 2px solid #eed757;
  padding-bottom: 5px;
}

.pwrap3 .list_partner {
  background: #0068B7;
  padding: 30px;
}

.pwrap3 .flxL {
  width: 30%;
}

.pwrap3 .flxR {
  width: 68%;
}

.pwrap4 {
  background: #E95A33;
  padding: 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .pwrap4 {
    display: block;
  }
  .pwrap4 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap4 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap4 a {
  color: #DC143C;
}

.pwrap4 a:hover {
  text-decoration: underline;
}

.pwrap4 .midashi_p {
  border-bottom: 2px solid #7CBDE0;
  padding-bottom: 5px;
}

.pwrap4 .list_partner {
  background: #C8E8EB;
  padding: 30px;
  color: #333;
}

.pwrap4 .flxL {
  width: 30%;
}

.pwrap4 .flxR {
  width: 68%;
}

.pwrap5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background: #B290EB;
  padding: 30px;
}

.pwrap5 a {
  color: #DC143C;
}

.pwrap5 a:hover {
  text-decoration: underline;
}

.pwrap5 .midashi_p {
  border-bottom: 2px solid #bce4e7;
  padding-bottom: 5px;
}

.pwrap5 .list_partner {
  background: #F28372;
  padding: 30px;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .pwrap5 {
    display: block;
  }
  .pwrap5 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap5 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap5 .flxL {
  width: 30%;
}

.pwrap5 .flxR {
  width: 68%;
}

.pwrap6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background: #F28372;
  padding: 30px;
  color: #ffffff;
}

.pwrap6 a {
  color: #DC143C;
}

.pwrap6 a:hover {
  text-decoration: underline;
}

.pwrap6 .midashi_p {
  border-bottom: 2px solid #e95a33;
  padding-bottom: 5px;
}

.pwrap6 .list_partner {
  background: #0068B7;
  padding: 30px;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .pwrap6 {
    display: block;
  }
  .pwrap6 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap6 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap6 .flxL {
  width: 30%;
}

.pwrap6 .flxR {
  width: 68%;
}

.pwrap7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background: #7CBDE0;
  padding: 30px;
  color: #ffffff;
}

.pwrap7 a {
  color: #DC143C;
}

.pwrap7 a:hover {
  text-decoration: underline;
}

.pwrap7 .midashi_p {
  border-bottom: 2px solid #eed757;
  padding-bottom: 5px;
}

.pwrap7 .list_partner {
  background: #E95A33;
  padding: 30px;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .pwrap7 {
    display: block;
  }
  .pwrap7 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .pwrap7 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.pwrap7 .flxL {
  width: 30%;
}

.pwrap7 .flxR {
  width: 68%;
}

.list_kids li {
  font-size: 1.25em;
  font-weight: bold;
  padding: 15px;
  border-bottom: 2px dotted #3abcae;
}

.list_kids2 li {
  font-size: 1.25em;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 1px dotted #fff;
}

.list_kids3 li {
  font-size: 1.25em;
  font-weight: bold;
  position: relative;
  padding: 15px 0 15px 30px;
  border-bottom: 2px dotted #3abcae;
}

.list_kids3 li:before {
  font-family: "Font Awesome 5 pro";
  content: "\f1ae";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1.6em;
  /*サイズ*/
  left: 0;
  /*アイコンの位置*/
  top: 32%;
  /*アイコンの位置*/
  color: #E95E49;
  /*アイコン色*/
}

.shoshinsha1 {
  border-bottom: 2px solid #bce4e7;
  padding-bottom: 5px;
  font-size: 1.8em;
  font-weight: bold;
  text-shadow: 2px 2px 0px #eed757;
  display: block;
}

@media only screen and (max-width: 640px) {
  .shoshinsha1 {
    margin-top: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .shoshinsha1 {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .shoshinsha1 {
    text-align: center;
  }
}

.icon {
  padding-left: 30px;
  position: relative;
  margin-left: 30px;
  font-size: 16px;
  text-shadow: none;
}

@media only screen and (max-width: 834px) {
  .icon {
    padding-left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .icon {
    padding-left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-top: 10px;
  }
}

.icon:before {
  content: url(../images/kids/shoshinsha2.png);
  position: absolute;
  top: -20%;
  left: 0;
}

@media only screen and (max-width: 834px) {
  .icon:before {
    position: inherit;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .icon:before {
    position: inherit;
  }
}

.shoshinsha2 {
  border-bottom: 2px solid #bce4e7;
  padding-bottom: 5px;
  font-size: 1.8em;
  font-weight: bold;
  text-shadow: 2px 2px 0px #3abcae;
  display: block;
}

@media only screen and (max-width: 640px) {
  .shoshinsha2 {
    margin-top: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .shoshinsha2 {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .shoshinsha2 {
    text-align: center;
  }
}

.shoshinsha3 {
  border-bottom: 2px solid #bce4e7;
  padding-bottom: 5px;
  font-size: 1.8em;
  font-weight: bold;
  text-shadow: 2px 2px 0px #e95a33;
  display: block;
}

@media only screen and (max-width: 640px) {
  .shoshinsha3 {
    margin-top: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .shoshinsha3 {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .shoshinsha3 {
    text-align: center;
  }
}

.shoshinsha4 {
  border-bottom: 2px solid #bce4e7;
  padding-bottom: 5px;
  font-size: 1.8em;
  font-weight: bold;
  text-shadow: 2px 2px 0px #0068B7;
  display: block;
}

@media only screen and (max-width: 640px) {
  .shoshinsha4 {
    margin-top: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .shoshinsha4 {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .shoshinsha4 {
    text-align: center;
  }
}

.en {
  font-family: 'Lobster', cursive;
  margin-right: 7px;
  font-size: 1.3em;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .en {
    display: block;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 834px) {
  .en {
    display: block;
    margin-bottom: 10px;
  }
}

.nittei {
  font-weight: bold;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .nittei {
    text-align: center;
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .nittei {
    text-align: center;
    display: block;
  }
}

.lesson01_k {
  background: #0068b7;
  color: #ffffff;
  position: relative;
}

.lesson01_k .txtback {
  background: #F2D63B;
  padding: 20px;
  color: #333;
}

.lesson02_k {
  background: #f2d63b;
  color: #333333;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .lesson02_k {
    padding: 0;
  }
}

.lesson02_k .txtback {
  background: #E95A33;
  padding: 20px;
  color: #fff;
}

.lesson03_k {
  background: #3abcae;
  color: #ffffff;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .lesson03_k {
    padding: 0;
  }
}

.lesson03_k .txtback {
  background: #0068B7;
  padding: 20px;
  color: #fff;
}

.lesson04_k {
  background: #e95a33;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .lesson04_k {
    padding: 0;
  }
}

.lesson04_k .txtback {
  background: #3ABCAE;
  padding: 20px;
  color: #fff;
}

.lesson01_h .txtback {
  background: #e95a33;
  padding: 20px;
  color: #fff;
}

.lesson02_h .txtback {
  background: #eed757;
  padding: 20px;
  color: #333;
}

.lesson03_h .txtback {
  background: #0068B7;
  padding: 20px;
  color: #fff;
}

.lesson04_h .txtback {
  background: #3ABCAE;
  padding: 20px;
  color: #fff;
}

.lesson01 {
  background: #0068b7;
  padding: 50px;
  color: #ffffff;
  position: relative;
}

.lesson01 .txtback {
  background: #F2D63B;
  padding: 20px;
  color: #333;
}

.lesson01 .icon {
  padding-left: 30px;
  position: relative;
  margin-left: 30px;
}

.lesson01 .icon:before {
  content: url(../images/kids/shoshinsha2.png);
  position: absolute;
  top: -20%;
  left: 0;
}

.lesson02 {
  background: #f2d63b;
  padding: 50px;
  color: #333333;
  position: relative;
}

.lesson02 .txtback {
  background: #E95A33;
  padding: 20px;
  color: #fff;
}

.lesson02 .icon {
  padding-left: 30px;
  position: relative;
  margin-left: 30px;
}

.lesson02 .icon:before {
  content: url(../images/kids/shoshinsha2.png);
  position: absolute;
  top: -20%;
  left: 0;
}

.lesson03 {
  background: #3abcae;
  padding: 50px;
  color: #ffffff;
  position: relative;
}

.lesson03 .txtback {
  background: #E95A33;
  padding: 20px;
  color: #fff;
}

.lesson03 .icon {
  padding-left: 30px;
  position: relative;
  margin-left: 30px;
}

.lesson03 .icon:before {
  content: url(../images/kids/shoshinsha2.png);
  position: absolute;
  top: -20%;
  left: 0;
}

.lesson04 {
  background: #e95a33;
  padding: 30px;
  color: #ffffff;
}

.lesson04 .txtback {
  background: #C8E8EA;
  padding: 20px;
  color: #333;
}

.lesson04 .icon {
  padding-left: 30px;
  position: relative;
  margin-left: 30px;
}

.lesson04 .icon:before {
  content: url(../images/kids/shoshinsha2.png);
  position: absolute;
  top: -20%;
  left: 0;
}

.sche_table {
  font-size: 13px;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sche_table {
    padding: 0 10px !important;
  }
}

@media only screen and (max-width: 834px) {
  .sche_table {
    padding: 0 10px !important;
  }
}

.sche_table .td_pink {
  background: #fcd9f1 !important;
}

.sche_table .td_blue {
  background: #c1edf0 !important;
}

.sche_table .td_top {
  background: #3abcae !important;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .sche_table .td_top {
    width: 190px;
  }
}

.sche_table .td_gley {
  background: #f7f7f7 !important;
}

.sche_table .td_spe {
  background: #fceb8e !important;
}

.sche_table .td_white {
  background: #fff !important;
}

.sche_table td {
  border: 1px solid #fff !important;
}

@media only screen and (max-width: 834px) {
  .sche_table td {
    padding: 10px !important;
  }
}

.midashi {
  text-align: center;
}

.midashi div {
  position: relative;
  line-height: 1.4;
  padding: 0.25em 1em;
  display: inline-block;
  top: 0;
  font-size: 1.25em;
  font-weight: bold;
}

.midashi div:before, .midashi div:after {
  position: absolute;
  top: 0;
  content: '';
  width: 8px;
  height: 100%;
  display: inline-block;
}

.midashi div:before {
  border-left: solid 1px #e95a33;
  border-top: solid 1px #e95a33;
  border-bottom: solid 1px #e95a33;
  left: 0;
}

.midashi div:after {
  content: '';
  border-top: solid 1px #e95a33;
  border-right: solid 1px #e95a33;
  border-bottom: solid 1px #e95a33;
  right: 0;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 30px solid #eed757;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
    text-align: center;
    margin-bottom: 10px;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  width: 48%;
}

.sec_02 .flxR {
  width: 48%;
}

.list {
  background: #fff;
  padding: 30px;
  border: 5px solid #bce4e7;
  border-radius: 10px;
}

.list li {
  position: relative;
  padding-left: 1.2em;
  /*アイコン分のスペース*/
  line-height: 1.4;
  margin: 10px 0;
  font-size: 1.25em;
  border-bottom: 2px dotted #eed757;
  padding-bottom: 10px;
}

.table_01 td {
  border: none !important;
}

.table_01 .title {
  color: #ffffff;
  background: #bce4e7;
  border-radius: 10px;
}

.dl_01 dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  border-bottom: 2px dotted #eed757 !important;
  font-weight: bold;
}

.dl_01 dd {
  border: none !important;
  width: 100% !important;
}

.dl_01 dl {
  display: block !important;
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #bce4e7;
}

.dl_02 dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  font-weight: bold;
  background: none !important;
}

.dl_02 dd {
  border: none !important;
  width: 100% !important;
  background: none !important;
  text-align: right;
}

.dl_02 dl {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #bce4e7;
}

.dl_03 a {
  color: #333333;
  font-weight: bold;
}

.dl_03 .tel2 {
  font-weight: bold;
}

.dl_03 dl {
  display: block !important;
}

.dl_03 dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  font-weight: bold;
  background: #3abcae !important;
  color: #ffffff;
}

.dl_03 dd {
  width: 100% !important;
  border: none !important;
}

.dl_04 dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  font-weight: bold;
  background: none !important;
}

.dl_04 dd {
  border: none !important;
  width: 100% !important;
  background: none !important;
  text-align: right;
}

.dl_04 dl {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #bce4e7;
}

@media only screen and (max-width: 640px) {
  .dl_04 dl {
    display: block !important;
  }
}

.flow_box {
  justify-content: space-between;
}

.flow_box .box {
  width: 46%;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .flow_box .box {
    width: 100%;
  }
}

.flow_box .h3_02 {
  font-size: 1.25em;
  border-bottom: 2px dotted #eed757;
  padding-bottom: 5px;
  margin: 10px 0;
}

.flow_box .h3_02 span {
  color: #e95a33;
  font-size: 1.5em;
  font-family: 'Lobster', cursive;
  text-shadow: 1px 1px 0px #000;
}

.rentalwrap {
  border: 1px solid #e95a33;
  padding: 30px;
  background: #BCE4E7;
}

.rentalwrap2 {
  border: 1px solid #e95a33;
  padding: 30px;
  background: #eed757;
}

.rentalwrap3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  border: 1px solid #3abcae;
  padding: 30px;
  background: #bce4e7;
}

.rentalwrap3 .flxL {
  width: 48%;
}

.rentalwrap3 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .rentalwrap3 {
    display: block;
  }
  .rentalwrap3 .flxL {
    width: 100% !important;
  }
  .rentalwrap3 .flxR {
    width: 100% !important;
  }
}

.rentalwrap4 {
  border: 1px solid #e95a33;
  padding: 30px;
  background: #eed757;
}

@media only screen and (max-width: 640px) {
  .imgC {
    width: 100% !important;
  }
}

.flow_box01 {
  justify-content: space-between;
}

.flow_box01 .box {
  width: 30%;
  margin: 10px;
}

.flow_box01 .box img {
  width: 360px !important;
  height: 270px !important;
  object-fit: cover;
  font-family: 'object-fit:cover;';
}

@media only screen and (max-width: 640px) {
  .flow_box01 .box img {
    width: 80% !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 640px) {
  .flow_box01 .box {
    width: 100%;
    text-align: center;
  }
}

.flow_box01 .h3_02 {
  font-size: 1.25em;
  border-bottom: 2px dotted #eed757;
  padding-bottom: 5px;
  margin: 10px 0;
}

.flow_box01 .h3_02 span {
  color: #e95a33;
  font-size: 1.5em;
  font-family: 'Lobster', cursive;
  text-shadow: 1px 1px 0px #000;
}

.flow_box02 {
  justify-content: space-between;
}

.flow_box02 .box {
  width: 46%;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .flow_box02 .box {
    width: 100%;
  }
}

.flow_box02 .h3_02 {
  font-size: 1.25em;
  border-bottom: 2px dotted #eed757;
  padding-bottom: 5px;
  margin: 10px 0;
}

.flow_box02 .h3_02 span {
  color: #e95a33;
  font-size: 1.5em;
  font-family: 'Lobster', cursive;
  text-shadow: 1px 1px 0px #000;
}

.h3_point h3 {
  font-weight: bold;
  color: #ffffff;
  background: #3abcae;
  padding: 10px;
}

.flow_box01 h3 {
  display: none;
}

.dl_taiken dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  font-weight: bold;
  background: none !important;
}

.dl_taiken dd {
  border: none !important;
  width: 100% !important;
  background: none !important;
  text-align: right;
}

.dl_taiken dl {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #e95a33;
}

@media only screen and (max-width: 640px) {
  .dl_taiken dl {
    display: block !important;
  }
}

.list2 li {
  background: #eed757;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  color: #ffffff;
}

.btnbox3 {
  justify-content: center;
  align-items: center;
}

.btnbox3 .box {
  width: 30%;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .btnbox3 .box {
    width: 100% !important;
    margin: 5px 0;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
}

.sec_03 .table_01 {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .table_01 {
    width: 100% !important;
  }
}

.sec_03 .table_01 {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .table_01 {
    width: 100% !important;
  }
}

.sec_04 {
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .table_02 {
    width: 100% !important;
  }
  .sec_05 .img {
    width: 100% !important;
    text-align: center;
  }
}

.sec_05 .table_02 {
  width: 48%;
}

.sec_05 .table_02 td {
  border: none !important;
}

.sec_05 .img {
  width: 48%;
}

.table_02:first-of-type dl {
  border-top: 1px solid #696969;
}

.table_02 dt {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .table_02 dt {
    display: block !important;
    width: 100% !important;
  }
}

.table_02 dd {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .table_02 dd {
    width: 100% !important;
  }
}

.table_02 dl {
  border-bottom: 1px solid #696969;
}

@media only screen and (max-width: 640px) {
  .table_02 dl {
    display: block !important;
  }
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .table_03 {
    width: 100% !important;
  }
  .sec_06 .img {
    width: 100% !important;
    text-align: center;
  }
}

.sec_06 .table_03 {
  width: 48%;
}

.sec_06 .table_03 td {
  border: none !important;
}

.sec_06 .table_03 .title {
  border-bottom: 5px solid #e95a33 !important;
  padding-bottom: 5px !important;
  font-size: 1.25em;
}

.sec_06 .img {
  width: 48%;
}

.sec_07 {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .table_04 {
    width: 100% !important;
  }
  .sec_07 .img {
    width: 100% !important;
    text-align: center;
  }
}

.sec_07 .table_04 {
  width: 28%;
}

.sec_07 .table_04 .img img {
  height: 180px !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.sec_07 .table_04 td {
  border: none !important;
}

.sec_07 .table_04 .title {
  border-bottom: 5px solid #e95a33 !important;
  padding-bottom: 5px !important;
  font-size: 1.25em;
}

.sec_07 .img {
  width: 48%;
}

.sec_08 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .sec_08 {
    display: block;
  }
  .sec_08 .txt {
    width: 100% !important;
  }
  .sec_08 .img {
    width: 100% !important;
    text-align: center;
  }
}

.sec_08 .txt {
  width: 48%;
}

.sec_08 .img {
  width: 48%;
}

.sec_09 {
  background: rgba(238, 215, 87, 0.8);
  padding: 0 0 60px;
  position: relative;
}

.sec_09 .inner {
  padding: 0;
}

.sec_09:before {
  content: "";
  background: url(../images/common/sche_bg.png) repeat-x;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  top: -99px;
  width: 100%;
  overflow: hidden;
  -moz-animation: infinity-loop 30s infinite linear 1s both;
  -webkit-animation: infinity-loop 30s infinite linear 1s both;
  animation: infinity-loop 30s infinite linear 1s both;
  z-index: -1;
}

.sec_point {
  background: rgba(238, 215, 87, 0.8);
  padding: 0 0 60px;
  position: relative;
}

.sec_point .inner {
  padding: 0;
}

.sec_point:before {
  content: "";
  background: url(../images/common/sche_bg.png) repeat-x;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  top: -99px;
  width: 100%;
  overflow: hidden;
  -moz-animation: infinity-loop 30s infinite linear 1s both;
  -webkit-animation: infinity-loop 30s infinite linear 1s both;
  animation: infinity-loop 30s infinite linear 1s both;
  z-index: -1;
}

.list3 li {
  padding: 10px 0 10px 45px;
  position: relative;
}

.list3 li:before {
  content: url(../images/common/blue_check_icon.png);
  position: absolute;
  left: 0;
  top: 17%;
}

.list4 {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}

.list4 li {
  padding: 15px;
}

.list4 li a {
  color: #333;
  text-decoration: underline;
}

.rental_btnlist ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .rental_btnlist ul {
    display: block;
  }
}

.rental_btnlist ul li {
  float: none !important;
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .rental_btnlist ul li {
    width: 100%;
  }
}

.rental_btnlist ul li a {
  background: #3abcae;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25em;
  border-radius: 50px;
  display: block;
  padding: 10px;
  margin: 10px;
}

.h3_taiken_wrap h3 {
  background: #3abcae;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
}

.campaign {
  font-size: 18px;
  line-height: 1.7em;
  background: #fff;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  border: 5px solid #3abcae;
  box-shadow: 4px 4px 1px #e95a33;
}

.campaign .title {
  font-size: 1.25em;
  color: #333;
  border-radius: 10px 10px 0 0;
  display: block;
  font-weight: bold;
}

.campaign .price {
  color: #ff0000;
  font-size: 26px;
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 1px #eed757;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #3abcae;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #e95a33;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.cont_form {
  border: 1px solid #ccc;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}

.cont_form a {
  color: #333333;
}

.cont_form a:hover {
  text-decoration: underline;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.txt_contact .tel2 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.txt_contact .tel2 a {
  color: #333333;
}

.gmap iframe {
  width: 100%;
}

.bg_100per_wrap {
  position: relative;
  padding: 290px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap .txt {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 350px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont1_bg.jpg);
  background-size: cover;
  z-index: 0;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #e95a33;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "游ゴシック", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.modaal-container {
  width: auto !important;
  border-radius: 20px !important;
}

.koushi_name div {
  font-size: 1.25em;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #e95a33;
}

.koushi_name div span {
  font-size: 13px;
}

.pop_img {
  padding: 10px 0 !important;
}

.about_lsn span {
  background: #bce4e7;
  border-radius: 10px;
  line-height: 3;
  width: 220px;
  display: block;
  text-align: center;
}

.about_lsn {
  background: #f7f7f7;
  padding: 10px;
}

.keireki span {
  background: #bce4e7;
  border-radius: 10px;
  line-height: 3;
  width: 220px;
  display: block;
  text-align: center;
}

.keireki {
  background: #f7f7f7;
  padding: 10px;
}

.comment span {
  background: #bce4e7;
  border-radius: 10px;
  line-height: 3;
  width: 220px;
  display: block;
  text-align: center;
}

.comment {
  background: #f7f7f7;
  padding: 10px;
}

.account {
  background: #f7f7f7;
  padding: 10px;
}

.account span {
  background: #bce4e7;
  border-radius: 10px;
  line-height: 3;
  width: 220px;
  display: block;
  text-align: center;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #e95a33;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.show {
  animation: pop-anim 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@media only screen and (max-width: 640px) {
  .show {
    animation: none;
  }
}

@keyframes pop-anim {
  0% {
    -moz-transform: scale(0) rotate(-10deg);
    -ms-transform: scale(0) rotate(-10deg);
    -webkit-transform: scale(0) rotate(-10deg);
    transform: scale(0) rotate(-10deg);
  }
  60% {
    -moz-transform: scale(1.1) rotate(0.5deg);
    -ms-transform: scale(1.1) rotate(0.5deg);
    -webkit-transform: scale(1.1) rotate(0.5deg);
    transform: scale(1.1) rotate(0.5deg);
  }
  100% {
    -moz-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  background: #3abcae;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999999999999;
}

.loading p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.front-logo img {
  width: 50% !important;
}

.instructor_01 {
  display: none;
}

.partner_01 {
  display: none;
}

.kids_01 {
  display: none;
}

.partner_01 {
  display: none;
}

.adult_01 {
  display: none;
}

.studio_image .slick-slide {
  height: auto !important;
}

.studio_image .slick-slide img {
  height: 510px !important;
  width: 738px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 834px) {
  .studio_image .slick-slide img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.studio_image .slick-dots li img {
  height: 100% !important;
  transition: .3s;
}

.studio_image .slick-dots img:hover {
  opacity: 0.5;
}

.studio_image .slick-dots li {
  margin: 0 1% 6px !important;
  width: 47% !important;
  height: 165px !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .studio_image .slick-dots li {
    width: 48% !important;
    height: 240px !important;
  }
}

.studio_image .slick-dots {
  position: inherit !important;
  text-align: left !important;
  bottom: 2px !important;
  height: 510px !important;
  margin-left: 10px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .studio_image .slick-dots {
    height: 740px !important;
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .studio_image .slick-dots {
    height: 550px !important;
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
}

.studio_image .slick-list {
  width: 162% !important;
}

@media only screen and (max-width: 834px) {
  .studio_image .slick-list {
    width: 100% !important;
  }
}

.studio_image .slick-slide {
  float: left !important;
}

.studio_image .thumb-item {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .studio_image .thumb-item {
    display: block;
  }
}

.cont_tel {
  font-size: 1.7em;
  font-weight: bold;
  line-height: 1em;
}

.cont_tel a {
  color: #333333;
}

.cont_tel span {
  font-size: 18px;
}

.policy_list {
  text-decoration: none;
}

.policy_list li {
  list-style: disc;
}

.health_01 {
  display: none;
}
